<template>
  <div >

    <div class="nk-app-root">
        <!-- main @s -->
        <div class="nk-main ">
            <!-- wrap @s -->
            <div class="nk-wrap nk-wrap-nosidebar">
                <!-- content @s -->
                <div class="nk-content ">
                    <div class="nk-block nk-block-middle nk-auth-body  wide-xs">
                        <div class="brand-logo pb-4 text-center">
                            <a href="html/index.html" class="logo-link">
                                <img class=" logo-img logo-img-lg" src="/images/8p2-Logo_rgb.png"  alt="logo">
                             </a>
                        </div>
                        <div class="card card-bordered">
                            <div class="card-inner card-inner-lg">
                                <div class="nk-block-head">
                                    <div class="nk-block-head-content">
                                        <h4 class="nk-block-title">Sign-In</h4>
                                        <div class="nk-block-des">
                                            <p>Access the <b>8.2 Inspect</b> panel using your username and password.</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="alert alert-danger alert-icon" v-if="error">
                                    <em class="icon ni ni-cross-circle"></em> {{errorMessage}}
                                </div>
                               
                                    <div class="form-group">
                                        <div class="form-label-group">
                                            <label class="form-label" for="default-01">Username</label>
                                        </div>
                                        <div class="form-control-wrap">
                                            <input type="text"  v-model="formUsername" @keyup="keyUp($event)" class="form-control form-control-lg" id="default-01" placeholder="Enter your username">
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <div class="form-label-group">
                                            <label class="form-label" for="password">Password</label>
                                        </div>
                                        <div class="form-control-wrap">
                                            <input type="password" v-model="formPwd"  @keyup="keyUp($event)" class="form-control form-control-lg" id="password" placeholder="Enter your password">
                                        </div>
                                    </div>
                                    <div class="form-group">
                                      <button v-if="processing" class="btn btn-lg btn-primary btn-block" type="button" disabled>
                                        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                        <span class="sr-only">Loading...</span>
                                      </button>
                                      <button v-if="!processing" @click="login()" class="btn btn-lg btn-primary btn-block">Sign in</button>
                                    </div>
                               
                              
                              
                            </div>
                        </div>
                    </div>
      
                </div>
                <!-- wrap @e -->
            </div>
            <!-- content @e -->
        </div>
        <!-- main @e -->
    </div>


  </div>
</template>

<script>


import { doLogin } from '@/services/login.service';  
import axios from 'axios';  


export default {
  name: 'Login',
  data () {
    return {
      formUsername: "",
      formPwd: "",
      error: false,
      errorMessage: "",
      processing: false, 
    }
  },
  components: {
  },
  mounted() {
      this.$store.state.account_setup = false
      this.$store.commit("set_projects", []);
  },
  methods: {

    login: function () {
      this.error = false

      if (!this.formUsername || this.formUsername.length < 3 &&  !this.formPwd && this.formPwd.trim().length < 3) {
        return 
      }
      

      this.$store.commit('auth_request') 

      this.processing = true

      doLogin({ username: this.formUsername.toLowerCase().trim(), password:  this.formPwd.trim() }).then( x => {

        this.processing = false
        if (x.status == 'error') {
          this.error = true
          this.errorMessage = x.message
          this.$store.commit('auth_error')
          localStorage.removeItem('token')
        } else {
          const token = x.data.token
          const user = x.data.user
          const role = x.data.role
          const name = x.data.name
          const company = x.data.company
  
          this.$store.state.user_role = role
          this.$store.state.name = name
          this.$store.state.company = company

          localStorage.setItem('user_role', JSON.stringify(role))
       
          localStorage.setItem('token', token)
          axios.defaults.headers.common['x-access-token'] = token
          this.$store.commit('auth_success', {token, user})
          this.$router.push({ name: 'dashboard' })
        }
      })
    },
    keyUp: function (ev) {
      if (ev.keyCode == 13) {
        this.login()
      }
    }
    
  },
}
</script>

<style>

</style>
