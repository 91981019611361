<template>
  <div class="nk-app-root">
    <!-- main @s -->
    <div class="nk-main">
      <!-- wrap @s -->
      <div class="nk-wrap">
        <Header />
        <!-- content @s -->
        <div class="nk-content">
          <div class="container wide-xl">
            <div class="nk-content-inner">
              <Navigation />
              <div class="nk-content-body">
                <div class="nk-content-wrap">
                  <div class="nk-block-head wide-md nk-block-head-lg">
                    <div class="nk-block-head-sub">
                      <span>8.2 Inspect</span>
                    </div>
                    <div class="nk-block-head-content">
                      <h2 class="nk-block-title fw-normal">Mobile App</h2>
                    </div>
                  </div>
                  <!-- .nk-block-head -->
                  <div class="nk-block">
                    <div class="support-topic-item card card-bordered">
                      <a class="support-topic-block card-inner" href="#">
                        <div class="support-topic-media">
                          <span
                            class="material-icons md-48"
                            style="font-size: 80px"
                            >adb</span
                          >
                        </div>
                        <div class="support-topic-context">
                          <h5 class="support-topic-title title">
                            8.2 Inspect for Android
                          </h5>
                          <div class="support-topic-info">
                            Before installing the APK, note the following:
                            <ul class="list">
                              <li>
                                The installation of APKs from sources other than
                                Google Play must be allowed. (Device management
                                > Unknown origin).
                              </li>
                              <li>
                                APK v 2.x.x does not overwrite v 1.x.x on your
                                smartphone. Both apps can be installed on your
                                smartphone if required.
                              </li>
                            </ul>
                          </div>
                        </div>
                      </a>
                    </div>
                    <!-- .support-topic-item -->



                    <div class="card card-bordered">
                      <div class="card-inner">
                        <div class="support-queue-item">
                          <a
                            class="support-queue-link"
                            href="https://inspect-app-v2.8p2.de/downloads/inspect2020.apk"
                            target="_blank"
                          >  
                            <div class="support-queue-media">
                              <em
                                class="
                                  icon icon-circle
                                  ni ni-shield-check
                                  bg-teal
                                "
                              ></em>
                            </div>
                            <div class="support-queue-body">
                              <div class="support-queue-context">
                                <h5 class="support-queue-title title">
                                  v 2.0.20
                                </h5>
                                <div class="support-queue-desc">
                                  Download APK
                                </div>
                              </div>
                              <div class="support-queue-update">
                                <span>20.03.22</span>
                              </div>
                            </div>
                          </a>
                        </div>
                        <!-- .support-queue-item -->
                        <div
                          id="faqs"
                          class="accordion"
                          style="margin-bottom: 10px"
                        >
                          <div class="accordion-item">
                            <a
                              href="#"
                              class="accordion-head collapsed"
                              data-toggle="collapse"
                              data-target="#faq-q21"
                              aria-expanded="false"
                            >
                              <h6 class="title">Info</h6>
                              <span class="accordion-icon"></span>
                            </a>
                            <div
                              class="accordion-body collapse"
                              id="faq-q21" 
                              data-parent="#faqs"
                              style=""
                            >
                              <div class="accordion-inner"> 
                                Adjustments for battery systems</div>
                            </div>
                          </div>
                          <!-- .accordion-item -->
                        </div>


                        <div class="support-queue-item">
                          <a
                            class="support-queue-link"
                            href="https://inspect-app-v2.8p2.de/downloads/inspect2019.apk"
                            target="_blank"
                          >
                            <div class="support-queue-media">
                              <em
                                class="
                                icon icon-circle
                                  ni ni-shield-check
                                   bg-gray bg-lighter
                                "
                              ></em>
                            </div>
                            <div class="support-queue-body">
                              <div class="support-queue-context">
                                <h5 class="support-queue-title title">
                                  v 2.0.19
                                </h5>
                                <div class="support-queue-desc">
                                  Download APK
                                </div>
                              </div>
                              <div class="support-queue-update">
                                <span>22.09.22</span>
                              </div>
                            </div>
                          </a>
                        </div>
                        <!-- .support-queue-item -->
                        <div
                          id="faqs"
                          class="accordion"
                          style="margin-bottom: 10px"
                        >
                          <div class="accordion-item">
                            <a
                              href="#"
                              class="accordion-head collapsed"
                              data-toggle="collapse"
                              data-target="#faq-q21"
                              aria-expanded="false"
                            >
                              <h6 class="title">Info</h6>
                              <span class="accordion-icon"></span>
                            </a>
                            <div
                              class="accordion-body collapse"
                              id="faq-q21" 
                              data-parent="#faqs"
                              style=""
                            >
                              <div class="accordion-inner"> 
                                Inspection status (8.2 AG)</div>
                            </div>
                          </div>
                          <!-- .accordion-item -->
                        </div>
                        
                        <div class="support-queue-item">
                          <a
                            class="support-queue-link"
                            href="https://inspect-app-v2.8p2.de/downloads/inspect2018.apk"
                            target="_blank"
                          >
                            <div class="support-queue-media">
                              <em
                                class="
                                icon icon-circle
                                  ni ni-shield-check
                                   bg-gray bg-lighter
                                "
                              ></em>
                            </div>
                            <div class="support-queue-body">
                              <div class="support-queue-context">
                                <h5 class="support-queue-title title">
                                  v 2.0.18
                                </h5>
                                <div class="support-queue-desc">
                                  Download APK
                                </div>
                              </div>
                              <div class="support-queue-update">
                                <span>16.08.22</span>
                              </div>
                            </div>
                          </a>
                        </div>
                        <!-- .support-queue-item -->
                        <div
                          id="faqs"
                          class="accordion"
                          style="margin-bottom: 10px"
                        >
                          <div class="accordion-item">
                            <a
                              href="#"
                              class="accordion-head collapsed"
                              data-toggle="collapse"
                              data-target="#faq-q19"
                              aria-expanded="false"
                            >
                              <h6 class="title">Info</h6>
                              <span class="accordion-icon"></span>
                            </a>
                            <div
                              class="accordion-body collapse"
                              id="faq-q19"
                              data-parent="#faqs"
                              style=""
                            >
                              <div class="accordion-inner"> 
                               Minor bug fixes<br />
                               Adjustments for booking new inspections                      </div>
                            </div>
                          </div>
                          <!-- .accordion-item -->
                        </div>

                        <div class="support-queue-item">
                          <a
                            class="support-queue-link"
                            href="https://inspect-app-v2.8p2.de/download_apk/inspect2017.apk"
                            target="_blank"
                          >
                            <div class="support-queue-media">
                              <em
                                class="
                             icon icon-circle
                                  ni ni-shield-check
                                   bg-gray bg-lighter
                                "
                              ></em>
                            </div>
                            <div class="support-queue-body">
                              <div class="support-queue-context">
                                <h5 class="support-queue-title title">
                                  v 2.0.17
                                </h5>
                                <div class="support-queue-desc">
                                  Download APK
                                </div>
                              </div>
                              <div class="support-queue-update">
                                <span>25.05.22</span>
                              </div>
                            </div>
                          </a>
                        </div>
                        <!-- .support-queue-item -->
                        <div
                          id="faqs"
                          class="accordion"
                          style="margin-bottom: 10px"
                        >
                          <div class="accordion-item">
                            <a
                              href="#"
                              class="accordion-head collapsed"
                              data-toggle="collapse"
                              data-target="#faq-q19"
                              aria-expanded="false"
                            >
                              <h6 class="title">Info</h6>
                              <span class="accordion-icon"></span>
                            </a>
                            <div
                              class="accordion-body collapse"
                              id="faq-q19"
                              data-parent="#faqs"
                              style=""
                            >
                              <div class="accordion-inner"> 
                               Minor bug fixes<br />
                               Adjustments for booking new inspections                      </div>
                            </div>
                          </div>
                          <!-- .accordion-item -->
                        </div>

                        <div class="support-queue-item">
                          <a
                            class="support-queue-link"
                            href="https://inspect-app-v2.8p2.de/download_apk/inspect2016.apk"
                            target="_blank"
                          >
                            <div class="support-queue-media">
                              <em
                                class="
                          icon icon-circle
                                  ni ni-shield-check
                                   bg-gray bg-lighter
                                "
                              ></em>
                            </div>
                            <div class="support-queue-body">
                              <div class="support-queue-context">
                                <h5 class="support-queue-title title">
                                  v 2.0.16
                                </h5>
                                <div class="support-queue-desc">
                                  Download APK
                                </div>
                              </div>
                              <div class="support-queue-update">
                                <span>26.04.22</span>
                              </div>
                            </div>
                          </a>
                        </div>
                        <!-- .support-queue-item -->
                        <div
                          id="faqs"
                          class="accordion"
                          style="margin-bottom: 10px"
                        >
                          <div class="accordion-item">
                            <a
                              href="#"
                              class="accordion-head collapsed"
                              data-toggle="collapse"
                              data-target="#faq-q18"
                              aria-expanded="false"
                            >
                              <h6 class="title">Info</h6>
                              <span class="accordion-icon"></span>
                            </a>
                            <div
                              class="accordion-body collapse"
                              id="faq-q18"
                              data-parent="#faqs"
                              style=""
                            >
                              <div class="accordion-inner">
                               New: Cover sheet and adapted report output for photovoltaic inspections<br />
                               Minor bug fixes                              </div>
                            </div>
                          </div>
                          <!-- .accordion-item -->
                        </div>


                        <div class="support-queue-item">
                          <a
                            class="support-queue-link"
                            href="https://inspect-app-v2.8p2.de/download_apk/inspect2015.apk"
                            target="_blank"
                          >
                            <div class="support-queue-media">
                              <em
                                class="
                  icon icon-circle
                                  ni ni-shield-check
                                   bg-gray bg-lighter
                                "
                              ></em>
                            </div>
                            <div class="support-queue-body">
                              <div class="support-queue-context">
                                <h5 class="support-queue-title title">
                                  v 2.0.15
                                </h5>
                                <div class="support-queue-desc">
                                  Download APK
                                </div>
                              </div>
                              <div class="support-queue-update">
                                <span>07.04.22</span>
                              </div>
                            </div>
                          </a>
                        </div>
                        <!-- .support-queue-item -->
                        <div
                          id="faqs"
                          class="accordion"
                          style="margin-bottom: 10px"
                        >
                          <div class="accordion-item">
                            <a
                              href="#"
                              class="accordion-head collapsed"
                              data-toggle="collapse"
                              data-target="#faq-q15"
                              aria-expanded="false"
                            >
                              <h6 class="title">Info</h6>
                              <span class="accordion-icon"></span>
                            </a>
                            <div
                              class="accordion-body collapse"
                              id="faq-q15"
                              data-parent="#faqs"
                              style=""
                            >
                              <div class="accordion-inner">
                               Save state of system data synchronization
                              </div>
                            </div>
                          </div>
                          <!-- .accordion-item -->
                        </div>

                        <div class="support-queue-item">
                          <a
                            class="support-queue-link"
                            href="https://inspect-app-v2.8p2.de/download_apk/inspect2014.apk"
                            target="_blank"
                          >
                            <div class="support-queue-media">
                              <em
                                class="
                                  icon icon-circle
                                  ni ni-shield-check
                                   bg-gray bg-lighter
                                "
                              ></em>
                            </div>
                            <div class="support-queue-body">
                              <div class="support-queue-context">
                                <h5 class="support-queue-title title">
                                  v 2.0.14
                                </h5>
                                <div class="support-queue-desc">
                                  Download APK
                                </div>
                              </div>
                              <div class="support-queue-update">
                                <span>28.02.22</span>
                              </div>
                            </div>
                          </a>
                        </div>
                        <!-- .support-queue-item -->
                        <div
                          id="faqs"
                          class="accordion"
                          style="margin-bottom: 10px"
                        >
                          <div class="accordion-item">
                            <a
                              href="#"
                              class="accordion-head collapsed"
                              data-toggle="collapse"
                              data-target="#faq-q12"
                              aria-expanded="false"
                            >
                              <h6 class="title">Info</h6>
                              <span class="accordion-icon"></span>
                            </a>
                            <div
                              class="accordion-body collapse"
                              id="faq-q12"
                              data-parent="#faqs"
                              style=""
                            >
                              <div class="accordion-inner">
                               Minor Bug fixes
                              </div>
                            </div>
                          </div>
                          <!-- .accordion-item -->
                        </div>



                        <div class="support-queue-item">
                          <a
                            class="support-queue-link"
                            href="#"
                            target="_blank"
                          >
                            <div class="support-queue-media">
                              <em
                                class="
                         icon icon-circle
                                  ni ni-shield-check
                                  bg-gray bg-lighter
                                "
                              ></em>
                            </div>
                            <div class="support-queue-body">
                              <div class="support-queue-context">
                                <h5 class="support-queue-title title">
                                  v 2.0.13
                                </h5>
                                <div class="support-queue-desc">
                                  Download APK
                                </div>
                              </div>
                              <div class="support-queue-update">
                                <span>28.02.22</span>
                              </div>
                            </div>
                          </a>
                        </div>
                        <!-- .support-queue-item -->
                        <div
                          id="faqs"
                          class="accordion"
                          style="margin-bottom: 10px"
                        >
                          <div class="accordion-item">
                            <a
                              href="#"
                              class="accordion-head collapsed"
                              data-toggle="collapse"
                              data-target="#faq-q14"
                              aria-expanded="false"
                            >
                              <h6 class="title">Info</h6>
                              <span class="accordion-icon"></span>
                            </a>
                            <div
                              class="accordion-body collapse"
                              id="faq-q14"
                              data-parent="#faqs"
                              style=""
                            >
                              <div class="accordion-inner">
                               Update: Time tracking fields<br >
                               Update: Dakks User <br >
                               Minor Bug fixes
                              </div>
                            </div>
                          </div>
                          <!-- .accordion-item -->
                        </div>


                        <div class="support-queue-item">
                          <a
                            class="support-queue-link"
                            href="https://inspect-app-v2.8p2.de/download_apk/inspect2012.apk"
                            target="_blank"
                          >
                            <div class="support-queue-media">
                              <em
                                class="
                                      icon icon-circle
                                  ni ni-shield-check
                                  bg-gray bg-lighter
                                "
                              ></em>
                            </div>
                            <div class="support-queue-body">
                              <div class="support-queue-context">
                                <h5 class="support-queue-title title">
                                  v 2.0.12
                                </h5>
                                <div class="support-queue-desc">
                                  Download APK
                                </div>
                              </div>
                              <div class="support-queue-update">
                                <span>15.12.21</span>
                              </div>
                            </div>
                          </a>
                        </div>
                        <!-- .support-queue-item -->
                        <div
                          id="faqs"
                          class="accordion"
                          style="margin-bottom: 10px"
                        >
                          <div class="accordion-item">
                            <a
                              href="#"
                              class="accordion-head collapsed"
                              data-toggle="collapse"
                              data-target="#faq-q12"
                              aria-expanded="false"
                            >
                              <h6 class="title">Info</h6>
                              <span class="accordion-icon"></span>
                            </a>
                            <div
                              class="accordion-body collapse"
                              id="faq-q12"
                              data-parent="#faqs"
                              style=""
                            >
                              <div class="accordion-inner">
                               Update: Display instructions for test results<br >
                               Bugfix: Machine names that contain "/"" <br >
                               Update: "Do not print" overwrites to "Always print"
                              </div>
                            </div>
                          </div>
                          <!-- .accordion-item -->
                        </div>



                        <div class="support-queue-item">
                          <a
                            class="support-queue-link"
                            href="https://inspect-app-v2.8p2.de/download_apk/inspect2011.apk"
                            target="_blank"
                          >
                            <div class="support-queue-media">
                              <em
                                class="
                                  icon icon-circle
                                  ni ni-shield-check
                                  bg-gray bg-lighter
                                "
                              ></em>
                            </div>
                            <div class="support-queue-body">
                              <div class="support-queue-context">
                                <h5 class="support-queue-title title">
                                  v 2.0.11
                                </h5>
                                <div class="support-queue-desc">
                                  Download APK
                                </div>
                              </div>
                              <div class="support-queue-update">
                                <span>01.12.21</span>
                              </div>
                            </div>
                          </a>
                        </div>
                        <!-- .support-queue-item -->
                        <div
                          id="faqs"
                          class="accordion"
                          style="margin-bottom: 10px"
                        >
                          <div class="accordion-item">
                            <a
                              href="#"
                              class="accordion-head collapsed"
                              data-toggle="collapse"
                              data-target="#faq-q11"
                              aria-expanded="false"
                            >
                              <h6 class="title">Info</h6>
                              <span class="accordion-icon"></span>
                            </a>
                            <div
                              class="accordion-body collapse"
                              id="faq-q11"
                              data-parent="#faqs"
                              style=""
                            >
                              <div class="accordion-inner">
                                Bugfix: update existing checklist of an inspection<br >
                                Bugfix: remove numbers inputs from results<br >
                                Update: Confirmation Dialog - "Insert into all inspections
                              </div>
                            </div>
                          </div>
                          <!-- .accordion-item -->
                        </div>


                        <div class="support-queue-item">
                          <a
                            class="support-queue-link"
                            href="https://inspect-app-v2.8p2.de/download_apk/inspect2010.apk"
                            target="_blank"
                          >
                            <div class="support-queue-media">
                              <em
                                class="
                                  icon icon-circle
                                  ni ni-shield-check
                                   bg-gray bg-lighter
                                "
                              ></em>
                            </div>
                            <div class="support-queue-body">
                              <div class="support-queue-context">
                                <h5 class="support-queue-title title">
                                  v 2.0.10
                                </h5>
                                <div class="support-queue-desc">
                                  Download APK
                                </div>
                              </div>
                              <div class="support-queue-update">
                                <span>15.11.21</span>
                              </div>
                            </div>
                          </a>
                        </div>
                        <!-- .support-queue-item -->
                        <div
                          id="faqs"
                          class="accordion"
                          style="margin-bottom: 10px"
                        >
                          <div class="accordion-item">
                            <a
                              href="#"
                              class="accordion-head collapsed"
                              data-toggle="collapse"
                              data-target="#faq-q1"
                              aria-expanded="false"
                            >
                              <h6 class="title">Info</h6>
                              <span class="accordion-icon"></span>
                            </a>
                            <div
                              class="accordion-body collapse"
                              id="faq-q1"
                              data-parent="#faqs"
                              style=""
                            >
                              <div class="accordion-inner">
                                Improvement in saving results and cover sheet data.<br >
                                Minor bug fixes.
                              </div>
                            </div>
                          </div>
                          <!-- .accordion-item -->
                        </div>

                        <div class="support-queue-item">
                          <a
                            class="support-queue-link"
                            href="https://inspect-app-v2.8p2.de/download_apk/inspect2009.apk"
                            target="_blank"
                          >
                            <div class="support-queue-media">
                              <em
                                class="
                                  icon icon-circle
                                  ni ni-shield-check
                                  bg-gray bg-lighter
                                "
                              ></em>
                            </div>
                            <div class="support-queue-body">
                              <div class="support-queue-context">
                                <h5 class="support-queue-title title">
                                  v 2.0.9
                                </h5>
                                <div class="support-queue-desc">
                                  Download APK
                                </div>
                              </div>
                              <div class="support-queue-update">
                                <span>01.09.21</span>
                              </div>
                            </div>
                          </a>
                        </div>
                        <!-- .support-queue-item -->
                        <div
                          id="faqs"
                          class="accordion"
                          style="margin-bottom: 10px"
                        >
                          <div class="accordion-item">
                            <a
                              href="#"
                              class="accordion-head collapsed"
                              data-toggle="collapse"
                              data-target="#faq-q10"
                              aria-expanded="false"
                            >
                              <h6 class="title">Info</h6>
                              <span class="accordion-icon"></span>
                            </a>
                            <div
                              class="accordion-body collapse"
                              id="faq-q10"
                              data-parent="#faqs"
                              style=""
                            >
                              <div class="accordion-inner">
                                New features, improvements and new way of
                                synchronization.
                              </div>
                            </div>
                          </div>
                          <!-- .accordion-item -->
                        </div>

<!--
                        <div class="support-queue-item">
                          <a
                            class="support-queue-link"
                            href="https://inspect-app-v2.8p2.de/download_apk/inspect1009.apk"
                            target="_blank"
                          >
                            <div class="support-queue-media">
                              <em
                                class="
                                  icon icon-circle
                                  ni ni-shield-check
                                  bg-gray bg-lighter
                                "
                              ></em>
                            </div>
                            <div class="support-queue-body">
                              <div class="support-queue-context">
                                <h5 class="support-queue-title title">
                                  v 1.0.9
                                </h5>
                                <div class="support-queue-desc">
                                  Download APK
                                </div>
                              </div>
                              <div class="support-queue-update">
                                <span>01.12.20</span>
                              </div>
                            </div>
                          </a>
                        </div>
-->


                        <!-- .support-queue-item -->
                      </div>
                    </div>
                  </div>
                  <!-- .nk-block -->
                  <div class="nk-block nk-block-lg">
                    <div class="card card-bordered border-primary">
                      <div class="card-inner">
                        <div class="nk-cta">
                          <div class="nk-cta-block">
                            <div class="nk-cta-img">
                              <em class="icon icon-circle ni ni-msg"></em>
                            </div>
                            <div class="nk-cta-text">
                              <p>
                                If have any questions please contact our
                                support.
                              </p>
                            </div>
                          </div>
                          <div class="nk-cta-action">
                            <a
                              href="mailto:inspect@8p2.de"
                              class="btn btn-primary"
                              >Contact Us</a
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- .card -->
                  </div>
                  <!-- .nk-block -->
                </div>
                <Footer />
              </div>
            </div>
          </div>
        </div>
        <!-- content @e -->
      </div>
      <!-- wrap @e -->
    </div>
    <!-- main @e -->
  </div>
</template>

<script>
import Header from "../../components/Header.vue";
import Navigation from "../../components/Navigation.vue";
import Footer from "../../components/Footer.vue";

export default {
  name: "MobileApp",
  components: {
    Header,
    Navigation,
    Footer,
  },
};
</script>

<style>
</style>
