<template>
  <div>
    <div style="padding: 0 0 20px 0; text-align: right">
      <div id="my-drop-zone-project" class="imgupload-conteiner">
        Select file (jpg, png) or drop here
      </div>

      <div
        class="col-sm-12"
        style="margin-top: 50px"
        v-if="imglib_list && imglib_list.length > 0"
      >
        <table class="table table-sm">
          <thead>
            <tr>
              <th style="text-align: left">Image</th>
              <th></th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in imglib_list" :key="item.uid">
              <td style="text-align: left">
                <a :href="pictureUrl(item.file)" target="_blank">
                  <img
                    :src="pictureUrl(item.file)"
                    style="width: 120px; padding-bottom: 5px"
                  />
                </a>
              </td>
              <td style="text-align: left"> 
                <div style="font-size: 12px; color: #999999">
                  {{ item.width }}x{{ item.height }}px
                </div>
              </td>
              <td style="text-align: right">
                <button
                  class="btn btn-dim btn-sm btn-outline-light"
                  @click="removeItem(item.uid, item.file)"
                >
                  Remove
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getProjectImgLib,
  removeProjectImgLib,
} from "@/services/reporting.service";

import Vue from "vue";
import Dropzone from "dropzone";

export default {
  name: "ProjectImage",
  components: {},
  data() {
    return {
      member_username: "",
      infoNotFound: false,
      insp_list: [],
      inspection_detail: null,
    };
  },
  computed: {
    imglib_list: function () {
      return this.$store.state.rp_imglib_project;
    },
    canSave: function () {
      return (
        this.project &&
        this.project.editor_list &&
        this.project.editor_list.indexOf(this.user_uid) != -1
      );
    },
  },
  mounted() {
    const ref = this;

    getProjectImgLib(this.$route.params.uid).then((d) => {
      this.$store.state.rp_imglib_project = d.data.imglib;
    });

    new Dropzone("#my-drop-zone-project", {
      paramName: "file", // The name that will be used to transfer the file
      maxFilesize: 4, // MB
      url: Vue.prototype.$apiBase + "/api/8p2reporting/import-imagelib",
      acceptedFiles: ".jpg,.png",
      addRemoveLinks: false,
      params: { project_uid: this.$route.params.uid, cat: "projectlib" },
      disablePreviews: true,
      headers: {
        "x-access-token": this.$store.state.token,
      },
      accept: function (file, done) {
        console.log(file);
        done();
      },
      success: function (file, response) {
        ref.$store.state.rp_imglib_project = response.data.imglib;
      },
    });
  },
  methods: {
    pictureUrl: function (file) {
      return this.$apiBase + "/project-file?file=" + file;
    },
    removeItem: function (uid, file) {
      removeProjectImgLib(uid, file, this.$route.params.uid).then((d) => {
        if (d.data && d.data.imglib) {
          this.$store.state.rp_imglib_project = d.data.imglib;
        }
      });
    },
    hideLoader: function () {
      if (this.fullLoader) {
        this.fullLoader.hide();
      }
    },
    showLoader: function () {
      this.fullLoader = this.$loading.show({
        // Optional parameters
        container: null,
        canCancel: false,
        height: 40,
        width: 40,
        loader: "spinner",
        color: "#333333",
        backgroundColor: "#EEF2FE",
        opacity: 0.6,
      });
    },
  },
};
</script>

<style>
@media (min-width: 576px) {
  .modal-dialog {
    max-width: 1000px;
  }
}
</style>
