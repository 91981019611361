<template>
  <div class="nk-app-root">
    <!-- main @s -->
    <div class="nk-main">
      <!-- wrap @s -->
      <div class="nk-wrap">
        <Header />
        <!-- content @s -->
        <div class="nk-content">
          <div class="container wide-xl">
            <div class="nk-content-inner">
              <Navigation />
              <div class="nk-content-body">
                <div class="nk-content-wrap">
                  <div class="nk-block-head wide-md nk-block-head-lg">
                    <div class="nk-block-head-sub">
                      <span>8.2 Inspect</span>
                    </div>
                    <div class="nk-block-head-content">
                      <h2 class="nk-block-title fw-normal">Web App</h2>
                    </div>
                  </div>
                  <!-- .nk-block-head -->

                  <div v-if="isFirefox" class="alert alert-warning alert-icon">
                    <em class="icon ni ni-alert-circle"></em> The Inspect app is
                    not compatible with your browser<br />
                    <strong>Please use Chrome.</strong>
                  </div>
                  <div class="nk-block">
                    <div class="support-topic-item card card-bordered">
                      <a
                        class="support-topic-block card-inner"
                        href="https://inspect-app-v2.8p2.de"
                        target="_blank"
                      >
                        <div class="support-topic-media">
                          <span
                            class="material-icons md-48"
                            style="font-size: 80px"
                            >open_in_browser</span
                          >
                        </div>
                        <div class="support-topic-context">
                          <h5 class="support-topic-title title">Inspect 2.0</h5>
                          <!-- div class="support-topic-info">You can find all of the questions and answers of general purpose.</div>-->
                        </div>
                        <div class="support-topic-action">
                          <em class="icon ni ni-chevron-right"></em>
                        </div>
                      </a>
                    </div>
                    <!-- .support-topic-item -->

                    <div id="faqs" class="accordion">
                      <div class="accordion-item">
                        <a
                          href="#"
                          class="accordion-head collapsed"
                          data-toggle="collapse"
                          data-target="#faq-q1"
                          aria-expanded="false"
                        >
                          <h6 class="title">Info</h6>
                          <span class="accordion-icon"></span>
                        </a>
                        <div
                          class="accordion-body collapse"
                          id="faq-q1"
                          data-parent="#faqs"
                          style="" 
                        >
                          <div class="accordion-inner">
                            <ul>
                              <li style="padding-bottom: 20px;">
                               25.04.22<br > 
                               New: Cover sheet and adapted report output for photovoltaic inspections<br />
                               New: Transfer test results to external reporting tool (Restricted access)<br />
                               Minor bug fixes
                              </li>
                              <li style="padding-bottom: 20px;">
                               15.12.21<br >
                               Update: Display instructions for test results<br >
                               Bugfix: Machine names that contain "/"" <br >
                               Update: "Do not print" overwrites to "Always print"
                              </li>
                              <li style="padding-bottom: 20px;">
                                01.12.21<br >
                                Bugfix: update existing checklist of an inspection<br >
                                Bugfix: remove numbers inputs from results<br >
                                Update: Confirmation Dialog - "Insert into all inspections
                              </li>
                              <li style="padding-bottom: 20px;">
                                15.11.21<br >
                                Improvement in saving results and
                                cover sheet data. Minor bug fixes.
                              </li>

                              <li style="padding-bottom: 20px;">
                                01.09.21
                                <br >New synchronization of system data
                                (checklists, machines,...).
                              </li>
                              <li style="padding-bottom: 20px;">
                                27.08.21
                                <br >New features, improvements and new way
                                of synchronization.
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <!-- .accordion-item -->
                    </div>

<!--
                    <div class="support-topic-item card card-bordered">
                      <a
                        class="support-topic-block card-inner"
                        href="https://inspect-app.8p2.de/version2020/#/"
                        target="_blank"
                      >
                        <div class="support-topic-media">
                          <span
                            class="material-icons md-48"
                            style="color: #8094ae; font-size: 80px"
                            >open_in_browser</span
                          >
                        </div>
                        <div class="support-topic-context">
                          <h5 class="support-topic-title title">
                            Inspect (v2020)
                          </h5>
                          <div class="support-topic-info">
                            This version will no longer be available after
                            10/15/2021.
                          </div>
                        </div>
                        <div class="support-topic-action">
                          <em class="icon ni ni-chevron-right"></em>
                        </div>
                      </a>
                    </div>

-->

                    <!-- .support-topic-item -->
                  </div>
                  <!-- .nk-block -->
                  <div class="nk-block nk-block-lg">
                    <div class="card card-bordered border-primary">
                      <div class="card-inner">
                        <div class="nk-cta">
                          <div class="nk-cta-block">
                            <div class="nk-cta-img">
                              <em class="icon icon-circle ni ni-msg"></em>
                            </div>
                            <div class="nk-cta-text">
                              <p>
                                If have any questions please contact our
                                support.
                              </p>
                            </div>
                          </div>
                          <div class="nk-cta-action">
                            <a
                              href="mailto:inspect@8p2.de"
                              class="btn btn-primary"
                              >Contact Us</a
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- .card -->
                  </div>
                  <!-- .nk-block -->
                </div>
                <Footer />
              </div>
            </div>
          </div>
        </div>
        <!-- content @e -->
      </div>
      <!-- wrap @e -->
    </div>
    <!-- main @e -->
  </div>
</template>

<script>
import Header from "../../components/Header.vue";
import Navigation from "../../components/Navigation.vue";
import Footer from "../../components/Footer.vue";

export default {
  name: "WebApp",
  components: {
    Header,
    Navigation,
    Footer,
  },
  data() {
    return {
      isFirefox: null,
    };
  },
  mounted() {
    this.isFirefox = navigator.userAgent.toLowerCase().indexOf("firefox") > -1;
  },
};
</script>

<style>
</style>
