<template>
  <div class="nk-app-root">
    <!-- main @s -->
    <div class="nk-main">
      <!-- wrap @s -->
      <div class="nk-wrap">
        <Header />
        <!-- content @s -->
        <div class="nk-content">
          <div class="container wide-xl">
            <div class="nk-content-inner">
              <Navigation />
              <div class="nk-content-body">
                <div class="nk-content-wrap">
                  <div class="nk-block-head wide-md nk-block-head-lg">
                    <div class="nk-block-head-sub">
                      <span>8.2 Inspect</span>
                    </div>
                    <div class="nk-block-head-content">
                      <h2 class="nk-block-title fw-normal">Help Center</h2>
                    </div>
                  </div>
                  <!-- .nk-block-head -->
                  <div class="nk-block">
                    <div class="card card-bordered">
                      <div class="card-inner">
                        <div class="support-queue-item">
                          <a
                            class="support-queue-link"
                            target="_blank"
                            href="https://inspect-app.8p2.de/videos/12-Deckblatt.mp4"
                          >
                            <div class="support-queue-media">
                              <span
                                class="
                                  icon icon-circle
                                  material-icons
                                  md-48
                                  bg-teal
                                "
                                >ondemand_video</span
                              >
                            </div>
                            <div class="support-queue-body">
                              <div class="support-queue-context">
                                <h5 class="support-queue-title title">
                                  Deckblatt (Inspektionsdatum, Wetter und Begleitpersonen)
                                </h5>
                              </div>
                            </div>
                          </a>
                        </div>
                        <!-- .support-queue-item -->

                        <div class="support-queue-item">
                          <a
                            class="support-queue-link"
                            target="_blank"
                            href="https://inspect-app.8p2.de/videos/11-Seriennummern.mp4"
                          >
                            <div class="support-queue-media">
                              <span
                                class="
                                  icon icon-circle
                                  material-icons
                                  md-48
                                  bg-teal
                                "
                                >ondemand_video</span
                              >
                            </div>
                            <div class="support-queue-body">
                              <div class="support-queue-context">
                                <h5 class="support-queue-title title">
                                 Seriennummern in der Übersicht
                                </h5>
                              </div>
                            </div>
                          </a>
                        </div>
                        <!-- .support-queue-item -->

                        <div class="support-queue-item">
                          <a
                            class="support-queue-link"
                            target="_blank"
                            href="https://inspect-app.8p2.de/videos/13-Zauberstab.mp4"
                          >
                            <div class="support-queue-media">
                              <span
                                class="
                                  icon icon-circle
                                  material-icons
                                  md-48
                                  bg-teal
                                "
                                >ondemand_video</span
                              >
                            </div>
                            <div class="support-queue-body">
                              <div class="support-queue-context">
                                <h5 class="support-queue-title title">
                                 Zauberstab - Ergebnisse ersetzen
                                </h5>
                              </div>
                            </div>
                          </a>
                        </div>
                        <!-- .support-queue-item -->

                        <div class="support-queue-item">
                          <a
                            class="support-queue-link"
                            target="_blank"
                            href="https://inspect-app.8p2.de/videos/15-Ergebnisse-ersetzen.mp4"
                          >
                            <div class="support-queue-media">
                              <span
                                class="
                                  icon icon-circle
                                  material-icons
                                  md-48
                                  bg-teal
                                "
                                >ondemand_video</span
                              >
                            </div>
                            <div class="support-queue-body">
                              <div class="support-queue-context">
                                <h5 class="support-queue-title title">
                                 Ergebnisse in allen anderen Inspektionen ersetzen
                                </h5>
                              </div>
                            </div>
                          </a>
                        </div>
                        <!-- .support-queue-item -->

                        <div class="support-queue-item">
                          <a
                            class="support-queue-link"
                            target="_blank"
                            href="https://inspect-app.8p2.de/videos/14-Ergebnisse-loeschen.mp4"
                          >
                            <div class="support-queue-media">
                              <span
                                class="
                                  icon icon-circle
                                  material-icons
                                  md-48
                                  bg-teal
                                "
                                >ondemand_video</span
                              >
                            </div>
                            <div class="support-queue-body">
                              <div class="support-queue-context">
                                <h5 class="support-queue-title title">
                                 Ergebnisse in allen Inspektionen löschen
                                </h5>
                              </div>
                            </div>
                          </a>
                        </div>
                        <!-- .support-queue-item -->

                        <div class="support-queue-item">
                          <a
                            class="support-queue-link"
                            target="_blank"
                            href="https://inspect-app.8p2.de/videos/06-Sprachauswahl.mp4"
                          >
                            <div class="support-queue-media">
                              <span
                                class="
                                  icon icon-circle
                                  material-icons
                                  md-48
                                  bg-teal
                                "
                                >ondemand_video</span
                              >
                            </div>
                            <div class="support-queue-body">
                              <div class="support-queue-context">
                                <h5 class="support-queue-title title">
                                  Sprachauswahl
                                </h5>
                              </div>
                            </div>
                          </a>
                        </div>
                        <!-- .support-queue-item -->
 
                        <div class="support-queue-item">
                          <a
                            class="support-queue-link"
                            target="_blank"
                            href="https://inspect-app.8p2.de/videos/02-In-alle-Inspektionen-einfuegen.mp4"
                          >
                            <div class="support-queue-media">
                              <span
                                class="
                                  icon icon-circle
                                  material-icons
                                  md-48
                                  bg-teal
                                "
                                >ondemand_video</span
                              >
                            </div>
                            <div class="support-queue-body">
                              <div class="support-queue-context">
                                <h5 class="support-queue-title title">
                                  Ergebnis in alle Inspektionen einfügen
                                </h5>
                              </div>
                            </div>
                          </a>
                        </div>
                        <!-- .support-queue-item -->
                        <div class="support-queue-item">
                          <a
                            class="support-queue-link"
                            target="_blank"
                            href="https://inspect-app.8p2.de/videos/03-Komponenten-ausblenden.mp4"
                          >
                            <div class="support-queue-media">
                              <span
                                class="
                                  icon icon-circle
                                  material-icons
                                  md-48
                                  bg-teal
                                "
                                >ondemand_video</span
                              >
                            </div>
                            <div class="support-queue-body">
                              <div class="support-queue-context">
                                <h5 class="support-queue-title title">
                                  Komponenten in der Checkliste ausblenden
                                </h5>
                              </div>
                            </div>
                          </a>
                        </div>
                        <!-- .support-queue-item -->
                        <div class="support-queue-item">
                          <a
                            class="support-queue-link"
                            target="_blank"
                            href="https://inspect-app.8p2.de/videos/04-Versionierung.mp4"
                          >
                            <div class="support-queue-media">
                              <span
                                class="
                                  icon icon-circle
                                  material-icons
                                  md-48
                                  bg-teal
                                "
                                >ondemand_video</span
                              >
                            </div>
                            <div class="support-queue-body">
                              <div class="support-queue-context">
                                <h5 class="support-queue-title title">
                                  Versionierung
                                </h5>
                              </div>
                            </div>
                          </a>
                        </div>
                        <!-- .support-queue-item -->
                        <div class="support-queue-item">
                          <a
                            class="support-queue-link"
                            target="_blank"
                            href="https://inspect-app.8p2.de/videos/07-Automatisches-Abhaken-1.mp4"
                          >
                            <div class="support-queue-media">
                              <span
                                class="
                                  icon icon-circle
                                  material-icons
                                  md-48
                                  bg-teal
                                "
                                >ondemand_video</span
                              >
                            </div>
                            <div class="support-queue-body">
                              <div class="support-queue-context">
                                <h5 class="support-queue-title title">
                                  Abhaken einer Unterkomponente als „Okay. Keine
                                  Anmerkung.“
                                </h5>
                              </div>
                            </div>
                          </a>
                        </div>
                        <!-- .support-queue-item -->
                        <div class="support-queue-item">
                          <a
                            class="support-queue-link"
                            target="_blank"
                            href="https://inspect-app.8p2.de/videos/07-Automatisches-Abhaken-4.mp4"
                          >
                            <div class="support-queue-media">
                              <span
                                class="
                                  icon icon-circle
                                  material-icons
                                  md-48
                                  bg-teal
                                "
                                >ondemand_video</span
                              >
                            </div>
                            <div class="support-queue-body">
                              <div class="support-queue-context">
                                <h5 class="support-queue-title title">
                                  Abhaken einer Komponente als „Okay. Keine
                                  Anmerkung.“
                                </h5>
                              </div>
                            </div>
                          </a>
                        </div>
                        <!-- .support-queue-item -->
                        <div class="support-queue-item">
                          <a
                            class="support-queue-link"
                            target="_blank"
                            href="https://inspect-app.8p2.de/videos/07-Automatisches-Abhaken-2.mp4"
                          >
                            <div class="support-queue-media">
                              <span
                                class="
                                  icon icon-circle
                                  material-icons
                                  md-48
                                  bg-teal
                                "
                                >ondemand_video</span
                              >
                            </div>
                            <div class="support-queue-body">
                              <div class="support-queue-context">
                                <h5 class="support-queue-title title">
                                  Abhaken einer Komponente mit zusätzlichen
                                  Anmerkungen
                                </h5>
                              </div>
                            </div>
                          </a>
                        </div>
                        <!-- .support-queue-item -->
                        <div class="support-queue-item">
                          <a
                            class="support-queue-link"
                            target="_blank"
                            href="https://inspect-app.8p2.de/videos/07-Automatisches-Abhaken-3.mp4"
                          >
                            <div class="support-queue-media">
                              <span
                                class="
                                  icon icon-circle
                                  material-icons
                                  md-48
                                  bg-teal
                                "
                                >ondemand_video</span
                              >
                            </div>
                            <div class="support-queue-body">
                              <div class="support-queue-context">
                                <h5 class="support-queue-title title">
                                  Wegkreuzen als „Nicht zutreffend. Nicht
                                  drucken.“
                                </h5>
                              </div>
                            </div>
                          </a>
                        </div>
                        <!-- .support-queue-item -->
                        <div class="support-queue-item">
                          <a
                            class="support-queue-link"
                            target="_blank"
                            href="https://inspect-app.8p2.de/videos/07-Automatisches-Abhaken-5.mp4"
                          >
                            <div class="support-queue-media">
                              <span
                                class="
                                  icon icon-circle
                                  material-icons
                                  md-48
                                  bg-teal
                                "
                                >ondemand_video</span
                              >
                            </div>
                            <div class="support-queue-body">
                              <div class="support-queue-context">
                                <h5 class="support-queue-title title">
                                  Shortcut: Nacharbeit im Büro.
                                </h5>
                              </div>
                            </div>
                          </a>
                        </div>
                        <!-- .support-queue-item -->
                        <div class="support-queue-item">
                          <a
                            class="support-queue-link"
                            target="_blank"
                            href="https://inspect-app.8p2.de/videos/07-Automatisches-Abhaken-6.mp4"
                          >
                            <div class="support-queue-media">
                              <span
                                class="
                                  icon icon-circle
                                  material-icons
                                  md-48
                                  bg-teal
                                "
                                >ondemand_video</span
                              >
                            </div>
                            <div class="support-queue-body">
                              <div class="support-queue-context">
                                <h5 class="support-queue-title title">
                                  Shortcut: Neues Ergebnis.
                                </h5>
                              </div>
                            </div>
                          </a>
                        </div>
                        <!-- .support-queue-item -->
                        <div class="support-queue-item">
                          <a
                            class="support-queue-link"
                            target="_blank"
                            href="https://inspect-app.8p2.de/videos/08-Sortieren-1.mp4"
                          >
                            <div class="support-queue-media">
                              <span
                                class="
                                  icon icon-circle
                                  material-icons
                                  md-48
                                  bg-teal
                                "
                                >ondemand_video</span
                              >
                            </div>
                            <div class="support-queue-body">
                              <div class="support-queue-context">
                                <h5 class="support-queue-title title">
                                  Sortieren: Mehrere Ergebnisse
                                </h5>
                              </div>
                            </div>
                          </a>
                        </div>
                        <!-- .support-queue-item -->
                        <div class="support-queue-item">
                          <a
                            class="support-queue-link"
                            target="_blank"
                            href="https://inspect-app.8p2.de/videos/08-Sortieren-2.mp4"
                          >
                            <div class="support-queue-media">
                              <span
                                class="
                                  icon icon-circle
                                  material-icons
                                  md-48
                                  bg-teal
                                "
                                >ondemand_video</span
                              >
                            </div>
                            <div class="support-queue-body">
                              <div class="support-queue-context">
                                <h5 class="support-queue-title title">
                                  Sortieren: Rotorblatt Ergebnisse
                                </h5>
                              </div>
                            </div>
                          </a>
                        </div>
                        <!-- .support-queue-item -->
                        <div class="support-queue-item">
                          <a
                            class="support-queue-link"
                            target="_blank"
                            href="https://inspect-app.8p2.de/videos/Neue-Abrechnung.mp4"
                          >
                            <div class="support-queue-media">
                              <span
                                class="
                                  icon icon-circle
                                  material-icons
                                  md-48
                                  bg-teal
                                "
                                >ondemand_video</span
                              >
                            </div>
                            <div class="support-queue-body">
                              <div class="support-queue-context">
                                <h5 class="support-queue-title title">
                                  Neue Form der Abrechnung von Inspektionen
                                </h5>
                              </div>
                            </div>
                          </a>
                        </div>
                        <!-- .support-queue-item -->
                        <div class="support-queue-item">
                          <a
                            class="support-queue-link"
                            target="_blank"
                            href="https://inspect-app.8p2.de/videos/09-Inspektionen-sortieren.mp4"
                          >
                            <div class="support-queue-media">
                              <span
                                class="
                                  icon icon-circle
                                  material-icons
                                  md-48
                                  bg-teal
                                "
                                >ondemand_video</span
                              >
                            </div>
                            <div class="support-queue-body">
                              <div class="support-queue-context">
                                <h5 class="support-queue-title title">
                                  Teilinspektionen sortieren
                                </h5>
                              </div>
                            </div>
                          </a>
                        </div>
                       <div class="support-queue-item">
                          <a
                            class="support-queue-link"
                            target="_blank"
                            href="https://inspect-app.8p2.de/videos/01-Abarbeitungskontrolle.mp4"
                          >
                            <div class="support-queue-media">
                              <span
                                class="
                                  icon icon-circle
                                  material-icons
                                  md-48
                                  bg-teal
                                "
                                >ondemand_video</span
                              >
                            </div>
                            <div class="support-queue-body">
                              <div class="support-queue-context">
                                <h5 class="support-queue-title title">
                                  Abarbeitungskontrolle
                                </h5>
                              </div>
                            </div>
                          </a>
                        </div>
                        <!-- .support-queue-item -->

                        <!-- .support-queue-item -->
                        <div class="support-queue-item">
                          <a
                            class="support-queue-link"
                            target="_blank"
                            href="https://inspect-app.8p2.de/videos/10-Inspektionen-ausblenden.mp4"
                          >
                            <div class="support-queue-media">
                              <span
                                class="
                                  icon icon-circle
                                  material-icons
                                  md-48
                                  bg-teal
                                "
                                >ondemand_video</span
                              >
                            </div>
                            <div class="support-queue-body">
                              <div class="support-queue-context">
                                <h5 class="support-queue-title title">
                                  Inspektionen ausblenden
                                </h5>
                              </div>
                            </div>
                          </a>
                        </div>
                        <!-- .support-queue-item -->
                      </div>
                    </div>
                  </div>
                  <!-- .nk-block -->

                  <div class="nk-block nk-block-lg">
                    <div class="card card-bordered">
                      <div class="card-inner">
                        <div class="nk-help">
                          <div class="nk-help-img">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 120 118"
                            >
                              <path
                                d="M8.916,94.745C-.318,79.153-2.164,58.569,2.382,40.578,7.155,21.69,19.045,9.451,35.162,4.32,46.609.676,58.716.331,70.456,1.845,84.683,3.68,99.57,8.694,108.892,21.408c10.03,13.679,12.071,34.71,10.747,52.054-1.173,15.359-7.441,27.489-19.231,34.494-10.689,6.351-22.92,8.733-34.715,10.331-16.181,2.192-34.195-.336-47.6-12.281A47.243,47.243,0,0,1,8.916,94.745Z"
                                transform="translate(0 -1)"
                                fill="#f6faff"
                              />
                              <rect
                                x="18"
                                y="32"
                                width="84"
                                height="50"
                                rx="4"
                                ry="4"
                                fill="#fff"
                              />
                              <rect
                                x="26"
                                y="44"
                                width="20"
                                height="12"
                                rx="1"
                                ry="1"
                                fill="#e5effe"
                              />
                              <rect
                                x="50"
                                y="44"
                                width="20"
                                height="12"
                                rx="1"
                                ry="1"
                                fill="#e5effe"
                              />
                              <rect
                                x="74"
                                y="44"
                                width="20"
                                height="12"
                                rx="1"
                                ry="1"
                                fill="#e5effe"
                              />
                              <rect
                                x="38"
                                y="60"
                                width="20"
                                height="12"
                                rx="1"
                                ry="1"
                                fill="#e5effe"
                              />
                              <rect
                                x="62"
                                y="60"
                                width="20"
                                height="12"
                                rx="1"
                                ry="1"
                                fill="#e5effe"
                              />
                              <path
                                d="M98,32H22a5.006,5.006,0,0,0-5,5V79a5.006,5.006,0,0,0,5,5H52v8H45a2,2,0,0,0-2,2v4a2,2,0,0,0,2,2H73a2,2,0,0,0,2-2V94a2,2,0,0,0-2-2H66V84H98a5.006,5.006,0,0,0,5-5V37A5.006,5.006,0,0,0,98,32ZM73,94v4H45V94Zm-9-2H54V84H64Zm37-13a3,3,0,0,1-3,3H22a3,3,0,0,1-3-3V37a3,3,0,0,1,3-3H98a3,3,0,0,1,3,3Z"
                                transform="translate(0 -1)"
                                fill="#798bff"
                              />
                              <path
                                d="M61.444,41H40.111L33,48.143V19.7A3.632,3.632,0,0,1,36.556,16H61.444A3.632,3.632,0,0,1,65,19.7V37.3A3.632,3.632,0,0,1,61.444,41Z"
                                transform="translate(0 -1)"
                                fill="#6576ff"
                              />
                              <path
                                d="M61.444,41H40.111L33,48.143V19.7A3.632,3.632,0,0,1,36.556,16H61.444A3.632,3.632,0,0,1,65,19.7V37.3A3.632,3.632,0,0,1,61.444,41Z"
                                transform="translate(0 -1)"
                                fill="none"
                                stroke="#6576ff"
                                stroke-miterlimit="10"
                                stroke-width="2"
                              />
                              <line
                                x1="40"
                                y1="22"
                                x2="57"
                                y2="22"
                                fill="none"
                                stroke="#fffffe"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                              />
                              <line
                                x1="40"
                                y1="27"
                                x2="57"
                                y2="27"
                                fill="none"
                                stroke="#fffffe"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                              />
                              <line
                                x1="40"
                                y1="32"
                                x2="50"
                                y2="32"
                                fill="none"
                                stroke="#fffffe"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                              />
                              <line
                                x1="30.5"
                                y1="87.5"
                                x2="30.5"
                                y2="91.5"
                                fill="none"
                                stroke="#9cabff"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <line
                                x1="28.5"
                                y1="89.5"
                                x2="32.5"
                                y2="89.5"
                                fill="none"
                                stroke="#9cabff"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <line
                                x1="79.5"
                                y1="22.5"
                                x2="79.5"
                                y2="26.5"
                                fill="none"
                                stroke="#9cabff"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <line
                                x1="77.5"
                                y1="24.5"
                                x2="81.5"
                                y2="24.5"
                                fill="none"
                                stroke="#9cabff"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <circle
                                cx="90.5"
                                cy="97.5"
                                r="3"
                                fill="none"
                                stroke="#9cabff"
                                stroke-miterlimit="10"
                              />
                              <circle
                                cx="24"
                                cy="23"
                                r="2.5"
                                fill="none"
                                stroke="#9cabff"
                                stroke-miterlimit="10"
                              />
                            </svg>
                          </div>
                          <div class="nk-help-text">
                            <h5>We’re here to help you!</h5>
                            <p class="text-soft">
                              Ask a question or file a support ticket or report
                              an issues. Our support will get back to you by
                              email.
                            </p>
                          </div>
                          <div class="nk-help-action">
                            <a
                              href="mailto:inspect@8p2.de"
                              class="btn btn-lg btn-outline-primary"
                              >Get Support Now</a
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <Footer />
              </div>
            </div>
          </div>
        </div>
        <!-- content @e -->
      </div>
      <!-- wrap @e -->
    </div>
    <!-- main @e -->
  </div>
</template>

<script>
import Header from "../../components/Header.vue";
import Navigation from "../../components/Navigation.vue";
import Footer from "../../components/Footer.vue";

export default {
  name: "MobileApp",
  components: {
    Header,
    Navigation,
    Footer,
  },
};
</script>

<style>
</style>
