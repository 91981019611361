import actions from './actions'
import mutations from './mutations'
import getters from './getters'

const token = localStorage.getItem('token');
const initialState = token
    ?  'success'
    :  '' ;

const state = {
    status: initialState,
    user_role: null, 
    user_uid: "", 
    account_setup: false, 
    loading_page: null, 
    name: "", 
    token: localStorage.getItem('token') || '',
    showSearch: false, 
    projects: [], 
    project: {}, 
    project_member: null, 
    machines: [], 
    insp_entry: null, 
    account_role: {},
    iec_daaks: null, 
    permissions_member: null, 
    permissions_users: null, 
    company: "", 
    rp_imglib_project: [], 
    main_chapters: [], 

    //
    rp_project: {}, 
    rp_imglib: [], 
    rp_inspections: [],
    rp_chapters_settings: [], 
    rp_report_settings: {},
  
    rp_state_color: {
      'V' : '#3BC600',
      'I' : '#009dff',
      'E' : '#0000ff',
      'P' : '#FFAA1D',
      'PP' : '#ff8000',
      'PPP' : '#ff0000'
    }
}

export default {
  state,
  getters,
  actions,
  mutations
}
