//import { fetchListData } from '../api/api'

import router from '../router';

export default {

  logout ({commit}) {
    commit('logout')
    localStorage.removeItem('token')
  },
  api_error ({ commit }, error) {
    console.log("api_error", error)
    commit('logout')
    router.push({ name: 'login' })
  },

  
}
