export default {
    isLoggedIn: state => !!state.token,
    authStatus: state => state.status,
    userRole: state => {
      if (state.user_role) {
        return state.user_role
      }

      if (localStorage.getItem('user_role')) {
        let role = localStorage.getItem('user_role')
        if (role) {
          return JSON.parse(role)
        } 
      }
    },
    

}
