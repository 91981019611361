<template>

    <!-- footer @s -->
    <div class="nk-footer">
        <div class="container wide-xl">
            <div class="nk-footer-wrap g-2">
                <div class="nk-footer-copyright"> &copy; 2022 8.2 Inspect
                </div>
                <!--
                <div class="nk-footer-links">
                    <ul class="nav nav-sm">
                        <li class="nav-item"><a class="nav-link" href="#">Terms</a></li>
                        <li class="nav-item"><a class="nav-link" href="#">Privacy</a></li>
                    </ul>
                </div>
                -->
            </div>
        </div>
    </div>
    <!-- footer @e -->
     
</template>

<script>


export default {
  name: 'Footer',
  components: {
  },
  computed: {

  }
}
</script>

<style>

</style>
