<template>
  <div class="modal fade" tabindex="-1" id="modalDefault">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <a href="#" class="close" data-dismiss="modal" aria-label="Close">
          <em class="icon ni ni-cross"></em>
        </a>
        <div class="modal-header">
          <h5 class="modal-title">Report Settings</h5>
        </div>
        <div class="modal-body">
          <form
            action="#"
            class="form-validate is-alter"
            novalidate="novalidate"
          >
            <div class="form-group">
              <div class="row gy-4">
                <div class="col-sm-6">
                  <div class="form-group">
                    <label class="form-label" for="default-01"
                      >Report picture</label
                    >
                    <div class="form-control-wrap">
                      <div class="custom-control custom-checkbox">
                        <input
                          type="checkbox"
                          class="custom-control-input"
                          id="customCheck1"
                          v-model="reportEntry.reportimage"
                        />
                        <label class="custom-control-label" for="customCheck1"
                          >Show Report image</label
                        >
                      </div>
                      <div
                        v-if="reportEntry.reportimage"
                        style="margin-top: 10px"
                      >
                        <select
                          class="form-control"
                          v-model="reportEntry.reportimagefile"
                        >
                          <option
                            :value="item.file"
                            v-for="item in imglib_list"
                            :key="item.uid"
                          >
                            {{ item.name }}
                          </option>
                        </select>
                        <div
                          class="form-group"
                          v-if="reportEntry.reportimage"
                          style="margin-top: 10px"
                        >
                          <label class="form-label" for="default-01"
                            >Report image width</label
                          >
                          <div class="form-control-wrap">
                            <input
                             v-model="reportEntry.reportimagewidth"
                              type="text"
                              class="form-control"
                              id="default-01"
                              placeholder="Image width"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-sm-6"></div>
              </div>
            </div>

            <table class="table" style="margin-top: 50px">
              <thead>
                <tr>
                  <th scope="col"></th>
                  <th scope="col"></th>
                  <th scope="col">New page</th>
                  <th scope="col">Hide</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in main_chapters" :key="item.chapter">
                  <td>{{ item.chapter }}</td>
                  <td>{{ item.title }}</td>
                  <td>
                    <div v-if="item.chapter != 'A'">
                      <input
                        type="checkbox"
                        :id="item.chapter"
                        :value="item.chapter"
                        name="pagenew"
                        v-model="reportEntry.checkedNewPage"
                      />
                    </div>
                  </td>
                  <td>
                    <div v-if="item.chapter == 'G'">
                      <input
                        type="checkbox"
                        :id="item.chapter"
                        :value="item.chapter"
                        name="pagenew"
                        v-model="reportEntry.checkedHide"
                      />
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>

            <div class="form-group" style="margin-top: 30px">
              <button
                type="button"
                class="btn btn-lg btn-primary"
                @click="updateSettings"
              >
                Save
              </button>
            </div>
          </form>
        </div>
        <div class="modal-footer bg-light"></div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getReportSettings,
  saveReportSettings,
} from "@/services/reporting.service";

export default {
  props: ["chapterId", "projectNumber", "inspectionEntry"],
  data() {
    return {
      reportEntry: {},
    };
  },
  computed: {
    imglib_list: function () {
      return this.$store.state.rp_imglib_project;
    },
    main_chapters: function () {
      return this.$store.state.main_chapters;
    },
  },
  mounted() {
    $("#modalDefault").modal("show");

    const ref = this;

    $("#modalDefault").on("hidden.bs.modal", function () {
      ref.$emit("dialogClosed");
    });

    this.reportEntry = {
      reportimage: false,
      reportimagefile: "",
      checkedNewPage: [],
      checkedHide: [],
    };

    getReportSettings(
      this.projectNumber,
      this.inspectionEntry.inspection,
      this.$store.state.rp_project.uid
    ).then((d) => {
      if (d) {
        if (d.data.report) {
          this.reportEntry = d.data.report;
        }

        this.$store.state.rp_imglib_project = d.data.imglib;
      }
    });

    // ------
  },
  methods: {
    updateSettings: async function () {
      await saveReportSettings(
        this.projectNumber,
        this.inspectionEntry.inspection,
        this.reportEntry
      ).then(() => {
        //this.$store.state.rp_chapters_settings = d.data.chapters_settings;
        $("#modalDefault").modal("hide");
      });
    },
  },
};
</script>

<style>
</style>