<template>
  <div class="nk-header nk-header-fixed ">
    <div class="container-lg wide-xl">
      <div class="nk-header-wrap">
        <div @click="goHome()" class="header-title">8.2 Inspect</div>
        <div class="nk-header-tools">
          <ul class="nk-quick-nav">
            <li class="dropdown user-dropdown">
              <a href="#" class="dropdown-toggle" data-toggle="dropdown">
                <div class="user-toggle">
                  <div class="user-avatar sm">
                    <em class="icon ni ni-user-alt"></em>
                  </div>
                  <div class="user-name dropdown-indicator d-none d-sm-block">
                    {{ loginName }}
                  </div>
                </div>
              </a>
              <div
                class="
                  dropdown-menu
                  dropdown-menu-md
                  dropdown-menu-right
                  dropdown-menu-s1
                "
              >
                <div
                  class="
                    dropdown-inner
                    user-card-wrap
                    bg-lighter
                    d-none d-md-block
                  "
                >
                  <div class="user-card">
                    <div class="user-avatar">
                      <span></span>
                    </div>
                    <div class="user-info">
                      <span class="lead-text">{{ loginName }}</span>
                      <!-- <span class="sub-text">info@softnio.com</span> -->
                    </div>
                    <!--
                                                    <div class="user-action">
                                                        <a class="btn btn-icon mr-n2" href="html/subscription/profile-setting.html"><em class="icon ni ni-setting"></em></a>
                                                    </div>
                                                    -->
                  </div>
                </div>

                <div class="dropdown-inner">
                  <ul class="link-list">
                    <li>
                      <router-link :to="{ name: 'logout', params: {} }" tag="a">
                        <em class="icon ni ni-signout"></em
                        ><span>Sign out</span>
                      </router-link>
                    </li>
                  </ul>
                </div>
              </div>
            </li>
            <!-- .dropdown -->

            <li class="d-lg-none">
              <a
                href="#"
                class="toggle nk-quick-nav-icon mr-n1"
                @click="mobileMenu()"
                ><em class="icon ni ni-menu"></em
              ></a>
            </li>
          </ul>
          <!-- .nk-quick-nav -->
        </div>
        <!-- .nk-header-tools -->
      </div>
      <!-- .nk-header-wrap -->
    </div>
    <!-- .container-fliud -->
  </div>
</template>

<script>

import { EventBus } from '@/event-bus.js';

export default {
  name: "Header",
  components: {},
  computed: {
    companyName: function () {
      return this.$store.state.company;
    },
    loginName: function () {
      return this.$store.state.name;
    },
  },
  methods: {
    goHome: function () {
      this.$router.push({ name: "dashboard" });
    },
    mobileMenu: function() {
        EventBus.$emit('toggle:mobile:menu')
    }
  },
};
</script>

<style>
.header-title {
  
  padding: 10px;
  cursor: pointer;
  font-family: Nunito, sans-serif;
  font-weight: 700;
  color: #364a63;
}
</style>
