<template>
  <div class="modal fade" tabindex="-1" id="modalDefault">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <a href="#" class="close" data-dismiss="modal" aria-label="Close">
          <em class="icon ni ni-cross"></em>
        </a>
        <div class="modal-header">
          <h5 class="modal-title">Settings</h5>
        </div>
        <div class="modal-body">
          <form
            action="#"
            class="form-validate is-alter"
            novalidate="novalidate"
          >
            <div class="form-group">
              <div class="row gy-4">
                <div class="col-sm-6">
                  <div class="form-group">
                    <label class="form-label" for="default-01"
                      >Localisation picture</label
                    >
                    <div class="form-control-wrap">
                      <div class="custom-control custom-checkbox">
                        <input
                          type="checkbox"
                          class="custom-control-input"
                          id="customCheck1"
                          v-model="chapterEntry.chapterimage"
                        />
                        <label class="custom-control-label" for="customCheck1"
                          >Chapter with image</label
                        >
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="form-group" v-if="chapterEntry.chapterimage">
                    <label class="form-label" for="default-01">&nbsp;</label>
                    <div class="form-control-wrap">
                      <div class="custom-control custom-checkbox">
                        <input
                          type="checkbox"
                          class="custom-control-input"
                          id="customCheck2"
                          v-model="chapterEntry.chapterincludes"
                        />
                        <label class="custom-control-label" for="customCheck2"
                          >includes all following chapters</label
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="form-group" v-if="chapterEntry.chapterimage">
              <div class="row gy-4">
                <div class="col-sm-6">
                  <select class="form-control"  v-model="chapterEntry.chapterfile">
                    <option
                      :value="item.file"
                      v-for="item in imglib_list"
                      :key="item.uid"
                     
                    >
                      {{ item.name }}
                    </option>
                  </select>
                </div>
                <div class="col-sm-6">
                    <div class="form-control-wrap">
                      <div class="custom-control custom-checkbox">
                        <input
                          type="checkbox"
                          class="custom-control-input"
                          id="customCheck9"
                          v-model="chapterEntry.chapterV"
                        />
                        <label class="custom-control-label" for="customCheck9"
                          >show V (OK) on image</label
                        >
                      </div>
                    </div>
                </div>
              </div>
              
            </div>

            <div class="form-group">
              <div class="row gy-4">
                <div class="col-sm-6">
                  <div class="form-group">
                    <label class="form-label" for="default-01">Format</label>
                    <div class="form-control-wrap">
                      <div class="custom-control custom-checkbox">
                        <input
                          type="checkbox"
                          class="custom-control-input"
                          id="customCheck3"
                          v-model="chapterEntry.newpage"
                        />
                        <label class="custom-control-label" for="customCheck3"
                          >Chapter on new page</label
                        >
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-sm-6">
                  <div class="form-group">
                    <label class="form-label" for="default-01">&nbsp;</label>
                    <div class="form-control-wrap">
                      <div class="custom-control custom-checkbox">
                        <input
                          type="checkbox"
                          class="custom-control-input"
                          id="customCheck9"
                          v-model="chapterEntry.newpagepictures"
                        />
                        <label class="custom-control-label" for="customCheck9"
                          >Pictures on new page</label
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="form-group" style="margin-top: 30px">
              <button
                type="button"
                class="btn btn-lg btn-primary"
                @click="updateSettings"
              >
                Save
              </button>
            </div>
          </form>
        </div>
        <div class="modal-footer bg-light"></div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getChapterSettings,
  saveChapterSettings,
} from "@/services/reporting.service";

export default {
  props: ["chapterId", "projectNumber", "inspectionEntry"],
  data() {
    return {
      chapterEntry: {},
    };
  },
  computed: {
    imglib_list: function () {
      return this.$store.state.rp_imglib;
    },
  },
  mounted() {
    $("#modalDefault").modal("show");

    const ref = this;

    $("#modalDefault").on("hidden.bs.modal", function () {
      ref.$emit("dialogClosed");
    });

    this.chapterEntry = {
        chapterimage: false, 
        chapterV: false, 
        newpage: false, 
    }

    getChapterSettings(
      this.projectNumber,
      this.inspectionEntry.inspection,
      this.chapterId
    ).then((d) => {
      if (d) {
        if (d.data && d.data.imglib) {
          this.$store.state.rp_imglib = d.data.imglib;
        }

        if (d.data && d.data.chapter) {
          this.chapterEntry = d.data.chapter;
        }
      }

    });

    // ------
  },
  methods: {
    updateSettings: async function () {
      await saveChapterSettings(
        this.projectNumber,
        this.inspectionEntry.inspection,
        this.chapterId,
        this.chapterEntry
      ).then((d) => {
        this.$store.state.rp_chapters_settings = d.data.chapters_settings;
        $("#modalDefault").modal("hide");
      });
    },
  },
};
</script>

<style>
</style>