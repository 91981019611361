<template>
  <div>
    <div class="card-inner">
      <div class="preview-block">
        <span class="preview-title-lg overline-title">State</span>
        <div class="row gy-4">
          <div class="col-sm-3">
            <div class="form-group">
              <label class="form-label" for="default-01">V</label>
              <div class="form-control-wrap">
                <input
                  v-model="report_settings.colorV"
                  type="text"
                  class="form-control"
                />
                <div
                  :style="{ backgroundColor: report_settings.colorV }"
                  class="form-color-box"
                ></div>
              </div>
            </div>
          </div>
          <div class="col-sm-3">
            <div class="form-group">
              <label class="form-label" for="default-01">I</label>
              <div class="form-control-wrap">
                <input
                  v-model="report_settings.colorI"
                  type="text"
                  class="form-control"
                />
                <div
                  :style="{ backgroundColor: report_settings.colorI }"
                  class="form-color-box"
                ></div>
              </div>
            </div>
          </div>
          <div class="col-sm-3">
            <div class="form-group">
              <label class="form-label" for="default-01">E</label>
              <div class="form-control-wrap">
                <input
                  v-model="report_settings.colorE"
                  type="text"
                  class="form-control"
                />
                <div
                  :style="{ backgroundColor: report_settings.colorE }"
                  class="form-color-box"
                ></div>
              </div>
            </div>
          </div>
          <div class="col-sm-3">
            <div class="form-group">
              <label class="form-label" for="default-01">P</label>
              <div class="form-control-wrap">
                <input
                  v-model="report_settings.colorP"
                  type="text"
                  class="form-control"
                />
                <div
                  :style="{ backgroundColor: report_settings.colorP }"
                  class="form-color-box"
                ></div>
              </div>
            </div>
          </div>
          <div class="col-sm-3">
            <div class="form-group">
              <label class="form-label" for="default-01">PP</label>
              <div class="form-control-wrap">
                <input
                  v-model="report_settings.colorPP"
                  type="text"
                  class="form-control"
                />
                <div
                  :style="{ backgroundColor: report_settings.colorPP }"
                  class="form-color-box"
                ></div>
              </div>
            </div>
          </div>
          <div class="col-sm-3">
            <div class="form-group">
              <label class="form-label" for="default-01">PPP</label>
              <div class="form-control-wrap">
                <input
                  v-model="report_settings.colorPPP"
                  type="text"
                  class="form-control"
                />
                <div
                  :style="{ backgroundColor: report_settings.colorPPP }"
                  class="form-color-box"
                ></div>
              </div>
            </div>
          </div>
        </div>
        <hr class="preview-hr" />
        <span class="preview-title-lg overline-title">Content</span>
        <div class="row gy-4">
          <div class="col-sm-6">
            <div class="form-group">
              <label class="form-label" for="default-01">Main Color</label>
              <div class="form-control-wrap">
                <input
                  v-model="report_settings.colorMain"
                  type="text"
                  class="form-control"
                />
                <div
                  :style="{ backgroundColor: report_settings.colorMain }"
                  class="form-color-box"
                ></div>
              </div>
            </div>
          </div>
          <div class="col-sm-6">
            <div class="form-group"></div>
          </div>
        </div>
        <hr class="preview-hr" />
        <div class="form-group">
          <div v-if="infoSaved" class="alert alert-success alert-icon">
            <em class="icon ni ni-check-circle"></em> Saved
          </div>
          <button
            type="submit"
            class="btn btn-lg btn-primary"
            @click="saveForm()"
          >
            Save
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { saveMainReportSettings } from "@/services/reporting.service";

export default {
  name: "ReportFooter",
  components: {},
  data() {
    return {
      infoSaved: false,
    };
  },
  computed: {
    report_settings: function () {
      return this.$store.state.rp_report_settings;
    },
  },

  methods: {
    saveForm: function () {
      saveMainReportSettings(this.$store.state.rp_report_settings).then((d) => {
        console.log("d", d);
      });
    },
  },
};
</script>

<style>
.form-control-wrap {
  position: relative;
}

.form-color-box {
  position: absolute;
  width: 20px;
  height: 20px;
  border: 1px solid #666666;
  top: 8px;
  right: 8px;
}
</style>