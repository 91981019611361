<template>
  <div>
    <div class="card-inner">
      <div class="preview-block">
        <span class="preview-title-lg overline-title">Header</span>
        <div class="row gy-4">
          <div class="col-sm-6">
            <div class="form-group">
              <label class="form-label" for="default-01">Titel</label>
              <div class="form-control-wrap">
                <input v-model="report_settings.title" type="text" class="form-control" />
              </div>
            </div>
          </div>
          <div class="col-sm-6">
            <div class="form-group">
              <label class="form-label" for="default-01">Logo</label>
              <div class="form-control-wrap">
                <input v-model="report_settings.logo" type="text" class="form-control" />
              </div>
            </div>
          </div>
        </div>
      

        <hr class="preview-hr" />
        <div class="form-group">
          <div v-if="infoSaved" class="alert alert-success alert-icon">
            <em class="icon ni ni-check-circle"></em> Saved
          </div>
          <button
            type="submit"
            class="btn btn-lg btn-primary"
            @click="saveForm()"
          >
            Save
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {
  saveMainReportSettings
} from "@/services/reporting.service";


export default {
  name: "ReportFooter",
  components: {
   

  },
  data() {
    return {
      infoSaved: false
    };
  },
  computed: {
    report_settings: function () {
      return this.$store.state.rp_report_settings;
    },
  },

  methods: {
    saveForm: function () {
        saveMainReportSettings(this.$store.state.rp_report_settings).then( d => {
            console.log("d", d)
        })
    }
  }

};
</script>

<style>
</style>