import Vue from 'vue'
import App from './App.vue'
import router from './router.js';
import store from './store.js';
import VueRouter from 'vue-router'
import  axios  from  'axios'
import VueLoading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';

  
Vue.config.productionTip = true    
 
  

Vue.prototype.$http = axios;

Vue.prototype.$konva = window.Konva 


Vue.use(VueRouter);
Vue.use(VueLoading)
Vue.use(VueSweetalert2);

Vue.use(require('vue-moment'));

// https://inspect.8p2.de/api/users/inbe/authenticate

Vue.prototype.$apiBase = (Vue.config.productionTip) ? "https://checklisten-designer.inspect-app.de/api"  : "http://localhost:3000" 
Vue.prototype.$http.defaults.baseURL = Vue.prototype.$apiBase;

new Vue({
  store,
  router,
  render: h => h(App),
}).$mount('#app')
