<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
import { accountSetup } from "@/services/login.service";
import axios from "axios";

export default {
  name: "App",
  components: {},
  created() {
    if (!this.$route.meta.nocheck) {
      axios.defaults.headers.common["x-access-token"] = this.$store.state.token;
      axios.defaults.headers.common["x-access-token"] = this.$store.state.token;

      this.$store.state.account_setup = true;
      this.$store.commit("set_loading_page", "setup");

      accountSetup()
        .then((d) => {
          //console.log("d", d)
          if (d.data.name) {
            this.$store.state.name = d.data.name;
          }
          if (d.data.company) {
            this.$store.state.company = d.data.company;
          }
          if (d.data.projects) {
            this.$store.commit("set_projects", d.data.projects);
          } else {
            this.$store.commit("set_projects", []);
          }

          if (d.data.account_role) {
            this.$store.state.account_role = d.data.account_role
          }

          this.$store.commit("set_loading_page", null);
        })
        .catch((e) => {
          this.$store.dispatch("api_error", e);
        });
    }
  },
};
</script>

<style>
@media only screen and (max-width: 760px) {
  .hide-mobile {
    display: none;
  }
}
</style>
