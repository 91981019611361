import VueRouter from 'vue-router'
import store from './store'
import Login from './pages/Login'
import Dashboard from './pages/account/Dashboard'
import WebApp from './pages/account/WebApp'
import MobileApp from './pages/account/MobileApp'
import Help from './pages/account/Help'
import Project from './pages/account/Project'
import Permissions from './pages/account/Permissions'
import BookingHistory  from './pages/account/BookingHistory'
import Report from './pages/account/Report'
import ChapterImages from './pages/account/ChapterImages'
import ReportSettings from './pages/account/ReportSettings'


const routes = [

  {
    path: '/dashboard',
    name: 'dashboard',
    component: Dashboard,
    meta: {
      requiresAuth: true
    },
  },
  {
    path: '/web-app',
    name: 'web-app',
    component: WebApp,
    meta: {
      requiresAuth: true
    },
  },
  {
    path: '/mobile-app',
    name: 'mobile-app',
    component: MobileApp,
    meta: {
      requiresAuth: true
    },
  },
  {
    path: '/help',
    name: 'help',
    component: Help,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/project-detail/:uid?',
    name: 'project',
    component: Project,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/permissions',
    name: 'permissions',
    component: Permissions,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/booking-history',
    name: 'bookinghistory',
    component: BookingHistory,
    meta: {
      requiresAuth: true
    }
  },

  

  {
    path: '/report/:projectUid?/:uid?',
    name: 'report',
    component: Report,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/image-library',
    name: 'imagelib',
    component: ChapterImages,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/report-settings',
    name: 'reportsettings',
    component: ReportSettings,
    meta: {
      requiresAuth: true
    }
  },

  


  {
    path: '/logout',
    name: 'logout',
    beforeEnter(to, from, next) {
      store.commit('logout')
      next('/login')
    }
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
    meta: {
      nocheck: true
    }
  },
  {
    path: '',
    component: Login
  }
];


const router = new VueRouter({
  mode: 'history',
  routes // short for `routes: routes`
})

router.beforeEach((to, from, next) => {
  //next() 
  //return
  if (to.matched.some(record => record.meta.requiresAuth)) {
    //console.log("store.getters.authStatus",  store.getters.authStatus)
    //console.log(store.state.token)
    if (store.getters.authStatus) {
      next()
      return
    }
    next('/login')
  } else {
    next()
  }
})

export default router