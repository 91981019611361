<template>
<div>

                            <div class="nk-aside" data-content="sideNav" data-toggle-overlay="true" data-toggle-screen="lg" data-toggle-body="true">
                                <div class="nk-sidebar-menu" data-simplebar>
                                    <!-- Menu -->
                                    <ul class="nk-menu">        
                                        <li class="nk-menu-heading">
                                            <h6 class="overline-title">Menu</h6>
                                        </li>
                                        <li class="nk-menu-item">
                                            <router-link :to="{ name: 'dashboard', params: {  }}" tag="a" class="nk-menu-link">
                                                <span class="nk-menu-icon"><em class="icon ni ni-dashboard"></em></span>
                                                <span class="nk-menu-text">Dashboard</span>
                                            </router-link>
                                        </li>
                                        <li class="nk-menu-item">
                                            <router-link :to="{ name: 'web-app', params: {  }}" tag="a" class="nk-menu-link">
                                                <span class="nk-menu-icon">
                                                    <span class="material-icons md-48">web</span>
                                                </span>
                                                <span class="nk-menu-text">Web App</span>
                                             </router-link>
                                        </li>
                                        <li class="nk-menu-item">
                                            <router-link :to="{ name: 'mobile-app', params: {  }}" tag="a" class="nk-menu-link">
                                                <span class="nk-menu-icon">
                                                    <span class="material-icons md-48">smartphone</span>
                                                </span>
                                                <span class="nk-menu-text">Mobile App</span>
                                             </router-link>
                                        </li>
                                        <li class="nk-menu-item" >
                                            <router-link :to="{ name: 'imagelib', params: {  }}" tag="a" class="nk-menu-link">
                                                <span class="nk-menu-icon">
                                                   <span class="nk-menu-icon"><em class="icon ni ni-upload-cloud"></em></span>
                                                </span>
                                                <span class="nk-menu-text">Chapter Images</span>
                                             </router-link>
                                        </li>
                                        <li class="nk-menu-item" >
                                            <router-link :to="{ name: 'reportsettings', params: {  }}" tag="a" class="nk-menu-link">
                                                <span class="nk-menu-icon">
                                                   <span class="nk-menu-icon"><em class="icon ni ni-setting"></em></span>
                                                </span>
                                                <span class="nk-menu-text">Report Settings</span>
                                             </router-link>
                                        </li>
                                        <li class="nk-menu-item" v-if="account_role_permissions">
                                            <router-link :to="{ name: 'permissions', params: {  }}" tag="a" class="nk-menu-link">
                                                <span class="nk-menu-icon">
                                                   <span class="nk-menu-icon"><em class="icon ni ni-dashboard"></em></span>
                                                </span>
                                                <span class="nk-menu-text">Permissions</span>
                                             </router-link>
                                        </li>
                                        <li class="nk-menu-item" v-if="bookings_role_permissions">
                                            <router-link :to="{ name: 'bookinghistory', params: {  }}" tag="a" class="nk-menu-link">
                                                <span class="nk-menu-icon">
                                                   <span class="nk-menu-icon"><em class="icon ni ni-dashboard"></em></span>
                                                </span>
                                                <span class="nk-menu-text">Booking History</span>
                                             </router-link>
                                        </li>
                                        <li class="nk-menu-item">
                                            <router-link :to="{ name: 'help', params: {  }}" tag="a" class="nk-menu-link">
                                                <span class="nk-menu-icon">
                                                    <span class="material-icons md-48">support</span>
                                                </span>
                                                <span class="nk-menu-text">Help Center</span>
                                             </router-link>
                                        </li>                                      
            
                                    </ul>
                                   
                                </div><!-- .nk-sidebar-menu -->
                                <div class="nk-aside-close">
                                    <a href="#" class="toggle" data-target="sideNav"><em class="icon ni ni-cross"></em></a>
                                </div><!-- .nk-aside-close -->
                            </div><!-- .nk-aside -->
</div>

</template>

<script>


import { EventBus } from '@/event-bus.js';

export default {
  name: 'Home',
  components: {

  },
  computed: {
    account_role_permissions: function () {
      return this.$store.state.account_role.permissions == 'write';
    },
    bookings_role_permissions: function () {
      return this.$store.state.account_role.bookings == "write";
    },
  },
  created() {
   
    EventBus.$off('toggle:mobile:menu')
    EventBus.$on('toggle:mobile:menu', this.toggleMobileMenu);
  },
  methods: {
    toggleMobileMenu: function () {
          $(".nk-aside").addClass("mobile-menu")
        if ($(".nk-aside").hasClass(("content-active"))) {
             $(".nk-aside").removeClass("content-active")
        }
        else {
             $(".nk-aside").addClass("content-active")
        }
    }
  },
}
</script>

<style>

</style>
