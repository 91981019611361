<template>
  <div>
    <table class="table">
      <thead>
        <tr>
          <th scope="col">Username</th>
          <th scope="col" class="hide-mobile">Name</th>
          <th scope="col">Editor</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="user in member_list" :key="user.uid">
          <td>{{ user.username }}</td>
          <td class="hide-mobile">
            {{ user.name }}
          </td>
          <td>
            <span
              v-if="!canSave && user.editor"
              class="material-icons md-48"
              style="font-size: 20px"
              >done</span
            >
            <span
              v-if="canSave && user_uid == user.uid"
              class="material-icons md-48"
              style="font-size: 20px"
              >done</span
            >

            <div
              class="custom-control custom-checkbox"
              v-if="user_uid != user.uid && canSave"
            >
              <input
                v-model="user.editor"
                type="checkbox"
                class="custom-control-input"
                :id="'customCheck' + user.uid"
                @change="changeEditor(user)"
              />
              <label
                class="custom-control-label"
                :for="'customCheck' + user.uid"
              ></label>
            </div>
          </td>

          <td>
            <div
              v-if="user_uid != user.uid && canSave"
              class="btn btn-outline-primary"
              style="border: none; padding: 3px 7px"
              @click="removeMember(user)"
            >
              Remove
            </div>
          </td>
        </tr>
      </tbody>
    </table>

    <div class="form-control-wrap" style="margin-top: 50px" v-if="canSave">
      <div class="input-group">
        <input
          v-model="member_username"
          type="text"
          class="form-control"
          placeholder="Username"
        />
        <div class="input-group-append">
          <button @click="addMember()" class="btn btn-outline-primary btn-dim">
            Add member
          </button>
        </div>
      </div>
    </div>
    <div
      class="alert alert-warning alert-icon"
      style="margin-top: 30px"
      v-if="infoNotFound"
    >
      <em class="icon ni ni-alert-circle"></em> User not found!
    </div>
  </div>
</template>

<script>
import {
  getMembers,
  addMember,
  updateEditor,
  removeMember,
} from "@/services/project.service";

export default {
  name: "Footer",
  components: {},
  data() {
    return {
      member_username: "",
      infoNotFound: false,
    };
  },
  computed: {
    member_list: function () {
      return this.$store.state.project_member;
    },
    user_uid: function () {
      return this.$store.state.user_uid;
    },
    project: function () {
      return this.$store.state.project;
    },
    canSave: function () {
      return (
        this.project &&
        this.project.editor_list &&
        this.project.editor_list.indexOf(this.user_uid) != -1
      );
    },
  },
  mounted() {
    getMembers(this.$route.params.uid).then((data) => {
      this.$store.state.project_member = data.data.user_list;
    });
  },
  methods: {
    showNotFound: function () {
      this.infoNotFound = true;
      let ref = this;
      setTimeout(function () {
        ref.infoNotFound = false;
      }, 4000);
    },
    hideLoader: function () {
      if (this.fullLoader) {
        this.fullLoader.hide();
      }
    },
    showLoader: function () {
      this.fullLoader = this.$loading.show({
        // Optional parameters
        container: null,
        canCancel: false,
        height: 40,
        width: 40,
        loader: "spinner",
        color: "#333333",
        backgroundColor: "#EEF2FE",
        opacity: 0.6,
      });
    },
    removeMember: function (user) {
      this.showLoader();
      removeMember(user, this.$route.params.uid).then((data) => {
        if (data.data && data.data.user_list) {
          this.$store.state.project_member = data.data.user_list;
        }
        this.hideLoader();
      });
    },
    changeEditor: function (user) {
      if (this.user_uid == user.uid) {
        user.editor = true;
        return;
      }
      this.showLoader();
      updateEditor(user, this.$route.params.uid).then((data) => {
        if (data.data && data.data.user_list) {
          this.$store.state.project_member = data.data.user_list;
        }
        this.hideLoader();
      });
    },
    addMember: function () {
      if (this.member_username && this.member_username.length > 2) {
        this.showLoader();

        addMember(this.member_username, this.$route.params.uid).then((data) => {
          if (data.data && data.data.user_list) {
            this.$store.state.project_member = data.data.user_list;
            this.member_username = "";
          } else {
            this.showNotFound();
          }

          this.hideLoader();
        });
      }
    },
  },
};
</script>

<style>
</style>
