<template>
  <div>
    <table class="table">
      <thead>
        <tr>
          <th scope="col">Username</th>
          <th scope="col">DAkkS</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="user in member_list" :key="user.user_uid">
          <td>{{ user.username }}</td>

          <td>
            <span
              v-if="!canSave && user.editor"
              class="material-icons md-48"
              style="font-size: 20px"
              >done</span
            >
            <span
              v-if="canSave && user_uid == user.user_uid"
              class="material-icons md-48"
              style="font-size: 20px"
              >done</span
            >

            <div
              class="custom-control custom-checkbox"
              v-if="user_uid != user.user_uid"
            >
              <input
                v-model="user.daaks"
                type="checkbox"
                class="custom-control-input"
                :id="'customCheck' + user.user_uid"
                @change="changeCat(user, 'daaks')"
              />
              <label
                class="custom-control-label"
                :for="'customCheck' + user.user_uid"
              ></label>
            </div>
          </td>

          <td>
            <div
              v-if="user_uid != user.user_uid"
              class="btn btn-outline-primary"
              style="border: none; padding: 3px 7px"
              @click="removeMember(user)"
            >
              Remove
            </div>
          </td>
        </tr>
      </tbody>
    </table>

    <div class="form-control-wrap" style="margin-top: 50px">
      <div class="input-group">
        <select name="pets" id="pet-select" class="form-control"  v-model="member_username2" >
          <option value="">-- Userlist ---</option>
          <option v-for="user in user_list" :key="user.username"  :value="user.username">{{user.name ? user.name : user.username }}</option>
        </select>
        <div class="input-group-append">
          <button @click="addMember2()" class="btn btn-outline-primary btn-dim">
            Add member
          </button>
        </div>
      </div>

      <div class="input-group" style="margin-top: 20px">
        <input
          v-model="member_username"
          type="text"
          class="form-control"
          placeholder="Username"
        />
        <div class="input-group-append">
          <button @click="addMember()" class="btn btn-outline-primary btn-dim">
            Add member
          </button>
        </div>
      </div>
    </div>
    <div
      class="alert alert-warning alert-icon"
      style="margin-top: 30px"
      v-if="infoNotFound"
    >
      <em class="icon ni ni-alert-circle"></em> User not found!
    </div>
  </div>
</template>

<script>
import {
  addMember,
  updateMember,
  removeMember,
  getMembers,
} from "@/services/account.service";

export default {
  name: "Footer",
  components: {},
  data() {
    return {
      member_username: "",
      infoNotFound: false,
      member_username2: "", 
    };
  },
  computed: {
    member_list: function () {
      return this.$store.state.permissions_member;
    },
    user_list: function () {
      return this.$store.state.permissions_users;
    },
    user_uid: function () {
      return this.$store.state.user_uid;
    },
  },
  mounted() {
    getMembers().then((data) => {
      this.$store.state.permissions_member = data.data.list;
      this.$store.state.permissions_users = data.data.users;
    });
  },
  methods: {
    showNotFound: function () {
      this.infoNotFound = true;
      let ref = this;
      setTimeout(function () {
        ref.infoNotFound = false;
      }, 4000);
    },
    hideLoader: function () {
      if (this.fullLoader) {
        this.fullLoader.hide();
      }
    },
    showLoader: function () {
      this.fullLoader = this.$loading.show({
        // Optional parameters
        container: null,
        canCancel: false,
        height: 40,
        width: 40,
        loader: "spinner",
        color: "#333333",
        backgroundColor: "#EEF2FE",
        opacity: 0.6,
      });
    },
    removeMember: function (user) {
      this.showLoader();
      removeMember(user.username).then((data) => {
        if (data.data && data.data.list) {
          this.$store.state.permissions_member = data.data.list;
        }
        this.hideLoader();
      });
    },
    changeCat: function (user, cat) {
      this.showLoader();
      updateMember(user.username, cat).then((data) => {
        if (data.data && data.data.list) {
          this.$store.state.permissions_member = data.data.list;
        }
        this.hideLoader();
      });
    },

    addMember2: function () {
      if (this.member_username2 && this.member_username2.length > 2) {
        this.showLoader();

        addMember(this.member_username2).then((data) => {
          if (data.data && data.data.list) {
            this.$store.state.permissions_member = data.data.list;
            this.member_username2 = ""
          } else {
            this.showNotFound();
          }

          this.hideLoader();
        });
      }
    },

    addMember: function () {
      if (this.member_username && this.member_username.length > 2) {
        this.showLoader();

        addMember(this.member_username).then((data) => {
          if (data.data && data.data.list) {
            this.$store.state.permissions_member = data.data.list;
            this.member_username = "";
          } else {
            this.showNotFound();
          }

          this.hideLoader();
        });
      }
    },
  },
};
</script>

<style>
</style>
