<template>
  <div class="nk-app-root">
    <!-- main @s -->
    <div class="nk-main">
      <!-- wrap @s -->
      <div class="nk-wrap">
        <Header />
        <!-- content @s -->
        <div class="nk-content">
          <div class="container wide-xl">
            <div class="nk-content-inner">
              <Navigation />
              <div class="nk-content-body">
                <div class="nk-content-wrap">
                  <div class="nk-block-head wide-md nk-block-head-lg">
                    <div class="nk-block-head-sub">
                      <span>8.2 Inspect</span>
                    </div>

                    <h2 class="nk-block-title fw-normal">Project</h2>
                    <div class="nk-block-des" v-if="project.external">
                      <div class="alert alert-info">
                        This is an external project.
                      </div>
                    </div>
                  </div>
                  <!-- .nk-block-head -->

                  <ul class="nav nav-tabs nav-tabs-s2">
                    <li class="nav-item">
                      <a
                        class="nav-link active"
                        data-toggle="tab"
                        href="#tabItem9"
                        >Details</a
                      >
                    </li>
                    <li class="nav-item">
                      <a class="nav-link" data-toggle="tab" href="#tabItem11"
                        >Member</a
                      >
                    </li>
                    <li class="nav-item">
                      <a class="nav-link" data-toggle="tab" href="#tabItem12"
                        >Inspections</a
                      >
                    </li>
                    <li class="nav-item">
                      <a class="nav-link" data-toggle="tab" href="#tabItem14"
                        >Images</a
                      >
                    </li>
                  </ul>

                  <div class="tab-content">
                    <!-- Project Detail -->
                    <div class="tab-pane active" id="tabItem9">
                      <div class="card card-preview">
                        <div class="card-inner">
                          <div class="preview-block">
                            <span class="preview-title-lg overline-title"
                              >Project</span
                            >
                            <div class="row gy-4">
                              <div class="col-sm-6">
                                <div class="form-group">
                                  <label class="form-label" for="default-01"
                                    >Number</label
                                  >
                                  <div class="form-control-wrap">
                                    <input
                                      type="text"
                                      v-model="project.projectnumber"
                                      class="form-control"
                                    />
                                  </div>
                                </div>
                              </div>
                              <div class="col-sm-6">
                                <div class="form-group">
                                  <label class="form-label" for="default-01"
                                    >Name</label
                                  >
                                  <div class="form-control-wrap">
                                    <input
                                      type="text"
                                      v-model="project.projectname"
                                      class="form-control"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="row gy-4">
                              <div class="col-sm-6">
                                <div class="form-group">
                                  <label class="form-label" for="default-01"
                                    >Type</label
                                  >
                                  <div class="form-control-wrap">
                                    <input
                                      type="text"
                                      v-model="project.projectType"
                                      class="form-control"
                                    />
                                  </div>
                                </div>
                              </div>
                              <div class="col-sm-6">
                                <div class="form-group">
                                  <label class="form-label" for="default-01"
                                    >Id</label
                                  >
                                  <div class="form-control-wrap">
                                    <input
                                      type="text"
                                      readonly=""
                                      v-model="project.projectId"
                                      class="form-control"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <hr class="preview-hr" />
                            <span class="preview-title-lg overline-title"
                              >Customer</span
                            >
                            <div class="row gy-4">
                              <div class="col-sm-6">
                                <div class="form-group">
                                  <label class="form-label" for="default-01"
                                    >Name</label
                                  >
                                  <div class="form-control-wrap">
                                    <input
                                      type="text"
                                      class="form-control"
                                      v-model="project.customer"
                                    />
                                  </div>
                                </div>
                              </div>
                              <div class="col-sm-6">
                                <div class="form-group">
                                  <label class="form-label" for="default-01"
                                    >Id</label
                                  >
                                  <div class="form-control-wrap">
                                    <input
                                      type="text"
                                      class="form-control"
                                      v-model="project.customerId"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="row gy-4">
                              <div class="col-sm-6">
                                <div class="form-group">
                                  <label class="form-label" for="default-01"
                                    >Street</label
                                  >
                                  <div class="form-control-wrap">
                                    <input
                                      type="text"
                                      class="form-control"
                                      v-model="project.customerStreet"
                                    />
                                  </div>
                                </div>
                              </div>
                              <div class="col-sm-6">
                                <div class="form-group">
                                  <label class="form-label" for="default-01"
                                    >Zip code</label
                                  >
                                  <div class="form-control-wrap">
                                    <input
                                      type="text"
                                      class="form-control"
                                      v-model="project.customerPostCode"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="row gy-4">
                              <div class="col-sm-6">
                                <div class="form-group">
                                  <label class="form-label" for="default-01"
                                    >City</label
                                  >
                                  <div class="form-control-wrap">
                                    <input
                                      type="text"
                                      class="form-control"
                                      v-model="project.customerCity"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <hr class="preview-hr" />
                          <span class="preview-title-lg overline-title"
                            >Contractor</span
                          >
                          <div class="row gy-4">
                            <div class="col-sm-6">
                              <div class="form-group">
                                <label class="form-label" for="default-01"
                                  >Date</label
                                >
                                <div class="form-control-wrap">
                                  <input
                                    type="text"
                                    class="form-control"
                                    placeholder="YYYY-MM-DD"
                                    v-model="project.contractDate"
                                  />
                                </div>
                              </div>
                            </div>
                            <div class="col-sm-6"></div>
                          </div>
                          <div class="row gy-4">
                            <div class="col-sm-6">
                              <div class="form-group">
                                <label class="form-label" for="default-01"
                                  >Name</label
                                >
                                <div class="form-control-wrap">
                                  <input
                                    type="text"
                                    class="form-control"
                                    v-model="project.contractor"
                                  />
                                </div>
                              </div>
                            </div>
                            <div class="col-sm-6">
                              <div class="form-group">
                                <label class="form-label" for="default-01"
                                  >Street</label
                                >
                                <div class="form-control-wrap">
                                  <input
                                    type="text"
                                    class="form-control"
                                    v-model="project.contractorStreet"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="row gy-4">
                            <div class="col-sm-6">
                              <div class="form-group">
                                <label class="form-label" for="default-01"
                                  >Zip code</label
                                >
                                <div class="form-control-wrap">
                                  <input
                                    type="text"
                                    class="form-control"
                                    v-model="project.contractorPostCode"
                                  />
                                </div>
                              </div>
                            </div>
                            <div class="col-sm-6">
                              <div class="form-group">
                                <label class="form-label" for="default-01"
                                  >City</label
                                >
                                <div class="form-control-wrap">
                                  <input
                                    type="text"
                                    class="form-control"
                                    v-model="project.contractorCity"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <hr class="preview-hr" />
                          <span class="preview-title-lg overline-title"
                            >Sites
                            <span v-if="project.sites"
                              >({{ project.sites.length }})</span
                            ></span
                          >
                          <div
                            class="btn btn-secondary btn-dim btn-sm"
                            @click="addSite()"
                            v-if="canSave"
                            style="margin: 10px 0"
                          >
                            <span>Add site</span>
                          </div>
                          <div
                            class="card card-preview"
                            v-for="item in project.sites"
                            :key="item.siteId"
                          >
                            <div class="card-inner">
                              <div class="preview-block">
                                <div class="row gy-4">
                                  <div class="col-sm-6">
                                    <div class="form-group">
                                      <label class="form-label" for="default-01"
                                        >ID</label
                                      >
                                      <div class="form-control-wrap">
                                        <input
                                          type="text"
                                          readonly=""
                                          class="form-control"
                                          v-model="item.siteId"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div class="col-sm-6">
                                    <div class="form-group">
                                      <label class="form-label" for="default-01"
                                        >Name</label
                                      >
                                      <div class="form-control-wrap">
                                        <input
                                          type="text"
                                          class="form-control"
                                          v-model="item.name"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div class="row gy-4">
                                  <div class="col-sm-6">
                                    <div class="form-group">
                                      <label class="form-label" for="default-01"
                                        >ZIP code</label
                                      >
                                      <div class="form-control-wrap">
                                        <input
                                          type="text"
                                          class="form-control"
                                          v-model="item.sitePostCode"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div class="col-sm-6">
                                    <div class="form-group">
                                      <label class="form-label" for="default-01"
                                        >City</label
                                      >
                                      <div class="form-control-wrap">
                                        <input
                                          type="text"
                                          class="form-control"
                                          v-model="item.siteCity"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div class="row gy-4">
                                  <div class="col-sm-6">
                                    <div class="form-group">
                                      <label class="form-label" for="default-01"
                                        >Technology</label
                                      >
                                      <div class="form-control-wrap">
                                        <input
                                          type="text"
                                          class="form-control"
                                          v-model="item.siteTechnology"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div class="col-sm-6">
                 
                                  </div>
                                </div>
                                <div class="row gy-4">
                                  <div class="col-sm-6">
                                    <div class="form-group">
                                      <label class="form-label" for="default-01"
                                        >Latitude</label
                                      >
                                      <div class="form-control-wrap">
                                        <input
                                          type="text"
                                          class="form-control"
                                          v-model="item.siteLatitude"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div class="col-sm-6">
                                    <div class="form-group">
                                      <label class="form-label" for="default-01"
                                        >Longitude</label
                                      >
                                      <div class="form-control-wrap">
                                        <input
                                          type="text"
                                          class="form-control"
                                          v-model="item.siteLongitude"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div class="form-group">
                                  <div
                                    class="col-sm-12"
                                    style="margin-top: 30px"
                                  >
                                    <span
                                      class="preview-title-lg overline-title"
                                      >Machines
                                      <span v-if="project.sites"
                                        >({{ item.machines.length }})</span
                                      ></span
                                    >
                                    <div
                                      class="btn btn-secondary btn-dim btn-sm"
                                      v-if="canSave"
                                      @click="
                                        selectMachine({ machineDb: '' }, item)
                                      "
                                      style="margin: 10px 0"
                                    >
                                      <span>Add machine</span>
                                    </div>
                                    <table class="table">
                                      <thead>
                                        <tr>
                                          <th scope="col">Number</th>
                                          <th scope="col" class="hide-mobile">
                                            Manufacturer
                                          </th>
                                          <th scope="col" class="hide-mobile">
                                            Type
                                          </th>
                                          <th></th>
                                           <th></th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr
                                          v-for="machine in item.machines"
                                          :key="machine._id"
                                        >
                                          <td>{{ machine.siteNumber }}</td>
                                          <td class="hide-mobile">
                                            {{ machine.machineManufacturer }}
                                          </td>
                                          <td class="hide-mobile">
                                            {{ machine.machineType }}
                                          </td>
                                          <td>
                                            <div
                                              class="btn btn-outline-primary"
                                              style="
                                                border: none;
                                                padding: 3px 7px;
                                              "
                                              @click="
                                                selectMachine(machine, item)
                                              "
                                            >
                                              Details
                                            </div>
                                                
                                          </td>
         <td>
                                          <div
                                              class="btn btn-outline-primary"
                                              style="
                                                border: none;
                                                padding: 3px 7px;
                                              "
                                              @click="
                                                duplicateMachine(machine, item)
                                              "
                                            >
                                            <em class="icon ni ni-folders"></em>
                                             
                                            </div>
                                          </td>
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                                <div
                                  style="float: right"
                                  v-if="canSave"
                                  class="btn btn-sm btn-outline-light"
                                  @click="deleteSite(item)"
                                >
                                  Delete site
                                </div>
                              </div>
                            </div>
                          </div>
                          <hr class="preview-hr" />
                          <div class="form-group">
                            <div
                              v-if="infoSaved"
                              class="alert alert-success alert-icon"
                            >
                              <em class="icon ni ni-check-circle"></em> Saved
                            </div>
                            <button
                              type="submit"
                              v-if="canSave"
                              class="btn btn-lg btn-primary"
                              @click="saveProject()"
                            >
                              Save project
                            </button>
                            <div
                              style="float: right"
                              v-if="canSave"
                              class="btn btn-sm btn-outline-light"
                              @click="deleteProjectAction()"
                            >
                              Delete project
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <!-- Project Detail -->

                    <div class="tab-pane" id="tabItem11">
                      <ProjectMember />
                    </div>

                    <div class="tab-pane" id="tabItem12">
                      <ProjectInspections />
                    </div>

                    <div class="tab-pane" id="tabItem14">
                      <ProjectImages />
                    </div>
                  </div>
                </div>

                <!-- Modal Content Code -->
                <div class="modal fade" id="modalDefault">
                  <div class="modal-dialog" role="document">
                    <div class="modal-content">
                      <a
                        href="#"
                        class="close"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <em class="icon ni ni-cross"></em>
                      </a>
                      <div class="modal-header">
                        <h5 class="modal-title">Machine</h5>
                      </div>
                      <div class="modal-body">
                        <div class="form-group" v-show="canSave">
                          <label class="form-label" for="default-01"
                            >Machines list</label
                          >
                          <select
                            id="cbMachineDb"
                            v-model="machineEntry.machineDb"
                            data-ui="lg"
                            data-search="on"
                          >
                            <option
                              :value="maEntry.value"
                              v-for="maEntry in machines"
                              :key="maEntry.uid"
                            >
                              {{ maEntry.text }}
                            </option>
                          </select>
                        </div>
                        <hr class="preview-hr" v-show="canSave" />
                        <form
                          action="#"
                          class="form-validate is-alter"
                          novalidate="novalidate"
                        >
                          <div class="form-group">
                            <div class="row gy-4">
                              <div class="col-sm-6">
                                <div class="form-group">
                                  <label class="form-label" for="default-01"
                                    >Site number</label
                                  >
                                  <div class="form-control-wrap">
                                    <input
                                      type="text"
                                      class="form-control"
                                      v-model="machineEntry.siteNumber"
                                    />
                                  </div>
                                </div>
                              </div>
                              <div class="col-sm-6">
                                <div class="form-group">
                                  <label class="form-label" for="default-01"
                                    >Name</label
                                  >
                                  <div class="form-control-wrap">
                                    <input
                                      type="text"
                                      class="form-control"
                                      v-model="machineEntry.name"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="row gy-4">
                              <div class="col-sm-6">
                                <div class="form-group">
                                  <label class="form-label" for="default-01"
                                    >Manufacturer</label
                                  >
                                  <div class="form-control-wrap">
                                    <input
                                      type="text"
                                      :readonly="canSave"
                                      class="form-control"
                                      v-model="machineEntry.machineManufacturer"
                                    />
                                  </div>
                                </div>
                              </div>
                              <div class="col-sm-6">
                                <div class="form-group">
                                  <label class="form-label" for="default-01"
                                    >Type</label
                                  >
                                  <div class="form-control-wrap">
                                    <input
                                      type="text"
                                      :readonly="canSave"
                                      class="form-control"
                                      v-model="machineEntry.machineType"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="row gy-4">
                              <div class="col-sm-6">
                                <div class="form-group">
                                  <label class="form-label" for="default-01"
                                    >Nominal power</label
                                  >
                                  <div class="form-control-wrap">
                                    <input
                                      type="text"
                                      class="form-control"
                                      v-model="machineEntry.nominalPower"
                                    />
                                  </div>
                                </div>
                              </div>
                              <div class="col-sm-6">
                                <div class="form-group">
                                  <label class="form-label" for="default-01"
                                    >Commissioning date</label
                                  >
                                  <div class="form-control-wrap">
                                    <input
                                      type="text"
                                      class="form-control"
                                      placeholder="YYYY-MM-DD"
                                      v-model="machineEntry.commissioningDate"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="row gy-4">
                              <div class="col-sm-6">
                                <div class="form-group">
                                  <label class="form-label" for="default-01"
                                    >Hub height</label
                                  >
                                  <div class="form-control-wrap">
                                    <input
                                      type="text"
                                      class="form-control"
                                      v-model="machineEntry.hubHeight"
                                    />
                                  </div>
                                </div>
                              </div>
                              <div class="col-sm-6">
                                <div class="form-group">
                                  <label class="form-label" for="default-01"
                                    >Rotor Diameter</label
                                  >
                                  <div class="form-control-wrap">
                                    <input
                                      type="text"
                                      class="form-control"
                                      v-model="machineEntry.rotorDiameter"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div class="row gy-4">
                              <div class="col-sm-6">
                                <div class="form-group">
                                  <label class="form-label" for="default-01"
                                    >Latitude</label
                                  >
                                  <div class="form-control-wrap">
                                    <input
                                      type="text"
                                      class="form-control"
                                      v-model="machineEntry.latitude"
                                    />
                                  </div>
                                </div>
                              </div>
                              <div class="col-sm-6">
                                <div class="form-group">
                                  <label class="form-label" for="default-01"
                                    >Longitude</label
                                  >
                                  <div class="form-control-wrap">
                                    <input
                                      type="text"
                                      class="form-control"
                                      v-model="machineEntry.longitude"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="form-group">
                            <button
                              type="button"
                              v-if="canSave"
                              class="btn btn-lg btn-primary"
                              @click="saveMachine()"
                            >
                              Save machine
                            </button>
                          </div>
                        </form>
                      </div>
                      <div class="modal-footer bg-light">
                  
                        <div
                          class="btn btn-outline-light"
                          v-if="canSave"
                          @click="deleteMachine(machineEntry)"
                        >
                          Delete
                        </div>
                      </div> 
                    </div>
                  </div>
                </div>

                <Footer />
              </div>
            </div>
          </div>
        </div>
        <!-- content @e -->
      </div>
      <!-- wrap @e -->
    </div>
    <!-- main @e -->
  </div>
</template>

<script>
import Header from "../../components/Header.vue";
import Navigation from "../../components/Navigation.vue";
import Footer from "../../components/Footer.vue";
import ProjectMember from "../../components/ProjectMember.vue";
import ProjectInspections from "../../components/ProjectInspections.vue";
import ProjectImages from "../../components/ProjectImages.vue"; 
import {
  getProject,
  updateProject,
  deleteProject,
} from "@/services/project.service";
import moment from "moment";

export default {
  name: "MobileApp",
  components: {
    Header,
    Navigation,
    Footer,
    ProjectMember,
    ProjectInspections,
    ProjectImages
  },
  mounted() {
    window.scrollTo(0, 0);
    getProject(this.$route.params.uid).then((data) => {
      if (data.data.project) {
        this.$store.state.project = data.data.project;
        this.$store.state.machines = data.data.machines;
        this.$store.state.user_uid = data.data.user_uid;
      }
    });

    $("#cbMachineDb").select2();

    let ref = this;

    $("#cbMachineDb").change(function () {
      const uid = $("#cbMachineDb").val();
      let entry = ref.getMachineDb(uid);

      if (entry) {
        ref.machineEntry.machineType = entry.machineType;
        ref.machineEntry.machineManufacturer = entry.machineManufacturer;
        ref.machineEntry.nominalPower = entry.nominalPower;
        ref.machineEntry.rotorDiameter = entry.rotorDiameter;
        ref.machineEntry.hubHeight = entry.hubHeight;
        ref.machineEntry.machineDb = uid;
        ref.$forceUpdate();
      }
    });
  },
  computed: {
    project: function () {
      return this.$store.state.project;
    },
    machines: function () {
      return this.$store.state.machines;
    },
    report_role_permissions: function () {
      return this.$store.state.account_role.report == 'write';
    },
    canSave: function () {
      return (
        this.project &&
        !this.project.external &&
        this.project.editor_list &&
        this.project.editor_list.indexOf(this.$store.state.user_uid) != -1
      );
    },
  },
  data() {
    return {
      machineEntry: {},
      fullLoader: null,
      infoSaved: false,
    };
  },
  methods: {
    showSaved: function () {
      this.infoSaved = true;
      let ref = this;
      setTimeout(function () {
        ref.infoSaved = false;
      }, 2000);
    },
    hideLoader: function () {
      if (this.fullLoader) {
        this.fullLoader.hide();
      }
    },
    showLoader: function () {
      this.fullLoader = this.$loading.show({
        // Optional parameters
        container: null,
        canCancel: false,
        height: 40,
        width: 40,
        loader: "spinner",
        color: "#333333",
        backgroundColor: "#EEF2FE",
        opacity: 0.6,
      });
    },
    uuid4() {
      const ho = (n, p) => n.toString(16).padStart(p, 0); /// Return the hexadecimal text representation of number `n`, padded with zeroes to be of length `p`
      const view = new DataView(new ArrayBuffer(16)); /// Create a view backed by a 16-byte buffer
      crypto.getRandomValues(new Uint8Array(view.buffer)); /// Fill the buffer with random data
      view.setUint8(6, (view.getUint8(6) & 0xf) | 0x40); /// Patch the 6th byte to reflect a version 4 UUID
      view.setUint8(8, (view.getUint8(8) & 0x3f) | 0x80); /// Patch the 8th byte to reflect a variant 1 UUID (version 4 UUIDs are)
      return `${ho(view.getUint32(0), 8)}-${ho(view.getUint16(4), 4)}-${ho(
        view.getUint16(6),
        4
      )}-${ho(view.getUint16(8), 4)}-${ho(view.getUint32(10), 8)}${ho(
        view.getUint16(14),
        1
      )}`; /// Compile the canonical textual form from the array data
    },
    getMachineDb: function (uid) {
      for (let i = 0; i < this.machines.length; i++) {
        if (this.machines[i].value == uid) {
          return this.machines[i].entry;
        }
      }
      return {};
    },
    duplicateMachine: function (data, site) {
      this.machineEntry = JSON.parse(JSON.stringify(data));
      this.siteEntry = site;

      this.machineEntry.uid = this.uuid4()
      this.machineEntry._id = this.machineEntry.uid
      this.siteEntry.machines.push(this.machineEntry);
 
      
    },
    selectMachine: function (data, site) {
      this.machineEntry = data; //JSON.parse(JSON.stringify(data));
      this.siteEntry = site;

      if (this.machineEntry.machineDb) {
        // $("#cbMachineDb").select2("val", this.machineEntry.machineDb);
        console.log("this.machineEntry.machineDb", this.machineEntry.machineDb);
      }

      $("#modalDefault").modal("show");
    },
    saveMachine: function () {
      if (this.machineEntry.uid) {
        for (let k = 0; k < this.siteEntry.machines.length; k++) {
          if (this.siteEntry.machines[k].uid == this.machineEntry.uid) {
            this.siteEntry.machines[k] = this.machineEntry;
          }
        }
        $("#modalDefault").modal("hide");
      } else {
        (this.machineEntry.uid = this.uuid4()),
          (this.machineEntry._id = this.machineEntry.uid);
        this.siteEntry.machines.push(this.machineEntry);
        $("#modalDefault").modal("hide");
      }
    },
    addSite: function () {
      if (!this.project.sites) {
        this.project.sites = [];
      }

      const siteId =
        moment().format("YYYYMMDDHHmmss") + Math.floor(Math.random() * 10) + 1;

      this.project.sites.push({
        _id: siteId,
        siteId: siteId,
        name: "",
        site: "",
        sitePostCode: "",
        siteCity: "",
        siteLongitude: "",
        siteLatitude: "",
        siteInstalledPower: "",
        siteTechnology: "",
        machines: [],
      });

      console.log(this.project);
    },
    saveProject: function () {
      this.showLoader();
      updateProject(this.project).then((d) => {
        this.hideLoader();
        this.showSaved();
        this.$store.commit("set_projects", d.data.projects);
      });
    },
    deleteProjectAction() {
      this.$swal({
        title: "Are you sure to delete this project ?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          this.showLoader();

          deleteProject(this.project).then((d) => {
            this.hideLoader();
            console.log(d.data);

            if (d.data.list) {
              this.$store.commit("set_projects", d.data.list);
            }
          });
        }
      });
    },
    deleteSite(site) {
      this.$swal({
        title: 'Are you sure to delete "' + site.name + '" ?',
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          for (let k = 0; k < this.project.sites.length; k++) {
            if (this.project.sites[k].uid == site.uid) {
              this.project.sites.splice(k, 1);
              return;
            }
          }
        }
      });
    },

    deleteMachine(machine) {
      this.$swal({
        title: 'Are you sure to delete "' + machine.name + '" ?',
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          console.log("weg damit", machine);
          $("#modalDefault").modal("hide");

          for (let k = 0; k < this.siteEntry.machines.length; k++) {
            if (this.siteEntry.machines[k].uid == machine.uid) {
              this.siteEntry.machines.splice(k, 1);
              return;
            }
          }
        }
      });
    },
  },
};
</script>

<style>
</style>
